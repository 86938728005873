import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Loader from 'react-loading-components';
import Api from '../../Services/Api';
import { toast } from 'react-toastify';
import './index.css';
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';

export default class PossiblePartiner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            firstNumber: '81 3137-1484',
            secondNumber: '81 3137-2487',
            institutionName: '',
            cnpj: '',
            modality: '',
            name: '',
            mail: '',
            city: '',
            cell: '',
            numStudents: '',
            message: '',
            telefone: "",
            loading: false,
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
      
        try {
          var formData = new FormData(e.target);
          var possiblePartinerData = {};
          formData.forEach((value, key) => { possiblePartinerData[key] = value.toUpperCase() });
      
          await Api.post('/possible_partner', possiblePartinerData);
      
          toast.success('Sua mensagem foi enviada.');
      
          this.setState({
            institutionName: '',
            cnpj: '',
            modality: '',
            name: '',
            mail: '',
            city: '',
            cell: '',
            telefone: "",
            numStudents: '',
            message: '',
            loading: false,
          });

          $('#city-select').val(null).trigger('change');
        } catch (error) {
          toast.error('Erro ao enviar mensagem, tente outra forma de contato.');
          this.setState({ loading: false });
        }
    };
      
    startSelect2 = () => {
        $.fn.select2.defaults.set("language", {
            noResults: function () { return "Sem resultados" },
            noMatches: function () { return "Nenhum resultado encontrado"; },
            inputTooShort: function (input, min) { return 'Digite no mínimo 2 caracteres.' },
            loadMore: function (pageNumber) { return "Buscando..."; },
            searching: function () { return "Buscando..."; }
        });
    
        const self = this; // Captura o contexto da classe para uso dentro da função assíncrona
    
        $('.city-select').select2({
            placeholder: "CIDADE",
            minimumInputLength: 2,
            theme: "bootstrap4",
            allowClear: true,
            ajax: {
                cache: true,
                url: function (params) {
                    return `https://servicodados.ibge.gov.br/api/v1/localidades/municipios?nome=${params.term}`;
                },
                dataType: 'json',
                delay: 250,
                processResults: function (data) {
                    return {
                        results: $.map(data, function (city) {
                            return {
                                text: `${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla}`.toUpperCase(),
                                id: `${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla}`.toUpperCase()
                            }
                        }),
                    };
                }
            }
        });
    }    

    handlePhoneChange = (event) => {
        const telefoneValue = event.target.value;
        this.setState({
          telefone: telefoneValue,
        });
    };

    componentDidMount() {
        this.startSelect2();
    }
    
    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="container-fluid possiblePartiner-content p-1 p-md-4">
                    <div className="row">
                        <div className="col-12">
                            <h2>Quero ser Parceiro</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 mb-3 mb-md-0">
                            <form id="possiblePartinerForm" onSubmit={this.handleSubmit}>
                                <input type="text" className="form-control mb-3" id="institutionName" name="institutionName" placeholder="Nome da Instituição" value={this.state.institutionName} onChange={(e) => this.setState({ institutionName: e.target.value })} />
                                <InputMask mask="99.999.999/9999-99" maskChar="" type="text" className="form-control mb-3" id="cnpj" name="cnpj" placeholder="CNPJ da Instituição" value={this.state.cnpj} onChange={(e) => this.setState({ cnpj: e.target.value })} />
                                <select className="form-control mb-3" defaultValue={this.state.modality} id="modality" name="modality" value={this.state.modality} onChange={(e) => this.setState({ modality: e.target.value })} >
                                    <option disabled value="">Modalidade</option>
                                    <option value="GRADUAÇÃO">GRADUAÇÃO</option>
                                    <option value="PÓS-GRADUAÇÃO">PÓS-GRADUAÇÃO</option>
                                    <option value="EDUCAÇÃO BÁSICA">EDUCAÇÃO BÁSICA</option>
                                    <option value="CURSOS TÉCNICOS">CURSOS TÉCNICOS</option>
                                    <option value="IDIOMAS">IDIOMAS</option>
                                    <option value="PREPARATÓRIO PARA CONCURSOS">PREPARATÓRIO PARA CONCURSOS</option>
                                    <option value="CURSOS PROFISSIONALIZANTES">CURSOS PROFISSIONALIZANTES</option>
                                    <option value="EJA - EDUCAÇÃO PARA JOVENS E ADULTOS">EJA - EDUCAÇÃO PARA JOVENS E ADULTOS</option>
                                    <option value="PRÉ-VESTIBULAR / ENEM">PRÉ-VESTIBULAR / ENEM</option>
                                </select>
                                <input type="text" className="form-control mb-3" id="name" name="name" placeholder="Seu Nome" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} required />
                                <input type="email" className="form-control mb-3" id="mail" name="email" placeholder="Seu e-mail" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })} required />
                                <div className="form-group">
                                    <select className="form-control mb-3 city-select" name="city" id="city-select" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                </div>
                                <TelefoneBrasileiroInput type="tel" className="form-control mb-3" id="cell" name="cell" placeholder="DDD + número" value={this.state.telefone} onChange={this.handlePhoneChange} temDDD required />
                                <InputMask mask="99999" maskChar="" type="text" className="form-control mb-3" id="numStudents" name="numStudents" placeholder="Quantidade de Estudantes" value={this.state.numStudents} onChange={(e) => this.setState({ numStudents: e.target.value })} />
                                <textarea name="message" id="message" className="form-control mb-3" placeholder="Como podemos ajudar?" value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} ></textarea>
                                {this.state.loading ?
                                    <Loader type='tail_spin' width={50} height={50} fill='#14496b' />
                                    :
                                    <button type="submit" value="send" className="btn btn-secondary btn-send-possiblePartiner text-white">Enviar dados</button>
                                }
                            </form>
                        </div>
                        <div className="col-md-5">
                            <h2 className="section-title">Fale com o <br />Brasil Mais Bolsas</h2>
                            <a href={`tel:${this.state.firstNumber.replace(/\D+/g, '')}`} className="text-color h5 d-block"><strong>{this.state.firstNumber}</strong></a>
                            <a href={`tel:${this.state.secondNumber.replace(/\D+/g, '')}`} className="text-color h5 d-block"><strong>{this.state.secondNumber}</strong></a>
                            <a href="https://wa.me/558197112781" className="text-whatsapp h5 d-block" ><strong className="wpp-color">Entre em contato através whatsapp</strong></a>
                            <a href="mailto:atendimento@brasilmaisbolsas.com.br" className="mb-5 text-color h5 d-block possiblePartiner-email">{this.state.email}</a>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

