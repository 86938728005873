import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="row bg-primary page-footer">
                <div className="row m-md-5 w-100">
                    <div className="col-md-4 mb-4 mt-4 mt-sm-0 text-center text-sm-left">
                        <img src={require('../../Images/logo.webp').default} width="200" height="91" alt="logo" className="ml-md-2"></img>
                    </div>
                    <div className="col-6 col-md-2 pl-5 pl-sm-0">
                        <h4 className="text-white mb-5">PARCEIROS</h4>
                        <ul className="list-unstyled">
                            <li className="mb-3"><a className="text-white" href="https://parceiro.brasilmaisbolsas.com.br">Portal do parceiro</a></li>
                            <li className="mb-3"><a className="text-white" href="/quero-ser-parceiro">Seja um parceiro</a></li>
                            <li className="mb-3"><a className="text-white" href="/parceiro_divulgacao">Parceiro de divulgação</a></li>
                            <li className="mb-3"><a className="text-white" href="/portal_vendedor">Portal do vendedor</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-2 pl-5 pl-sm-0">
                        <h4 className="text-white mb-5">SOBRE</h4>
                        <ul className="list-unstyled">
                            <li className="mb-3"><a className="text-white" href="/como_funciona">Como funciona</a></li>
                            <li className="mb-3"><a className="text-white" href="/quem_somos">Quem somos</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-2 pl-5 pl-sm-0">
                        <h4 className="text-white mb-5">LINKS ÚTEIS</h4>
                        <ul className="list-unstyled">
                            <li className="mb-3"><a className="text-white" target="_blank" rel="noopener noreferrer" href="https://enem.inep.gov.br/">Enem</a></li>
                            <li className="mb-3"><a className="text-white" target="_blank" rel="noopener noreferrer" href="http://www.sisu.mec.gov.br/">Sisu</a></li>
                            <li className="mb-3"><a className="text-white" target="_blank" rel="noopener noreferrer" href="http://prouniportal.mec.gov.br/">Prouni</a></li>
                            <li className="mb-3"><a className="text-white" target="_blank" rel="noopener noreferrer" href="http://sisfiesportal.mec.gov.br/">Fies</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-2 pl-5 pl-sm-0">
                        <h4 className="text-white mb-5">CONTATO</h4>
                        <ul className="list-unstyled">
                            <li className="mb-3"><a className="text-white" href="/fale_conosco">Fale Conosco</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
