import React, { Component } from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import './index.css'

export default class PrivacyPolicyTermsOfUse extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="container-fluid">
        <Header />

        <div className="container-fluid useterms-content p-1 p-md-4">
          
          <h1>Política de Privacidade e Termos de Uso</h1>

          <p>O Brasil Mais Bolsas tem o compromisso, através desta Política de Privacidade, de esclarecer como tratamos e protegemos as informações dos nossos Clientes e Visitantes.</p>

          <p>Adicionalmente, apresentamos a seguir os Termos de Uso do site, cuja aceitação é fundamental para que os Usuários e/ou Clientes possam utilizar o portal eletrônico do Brasil Mais Bolsas.</p>

          <p>Em conformidade com as regulamentações vigentes, incluindo a Lei Geral de Proteção de Dados – Lei Federal 13.709/18 – e considerando a importância da segurança daqueles que acessam nosso site, forneceremos informações claras e transparentes sobre como as informações são tratadas, os métodos de tratamento, as bases legais, as opções de consulta, alteração e exclusão de dados pessoais em nosso banco de dados, bem como a política de cookies que adotamos.</p>

          <p>Portanto, para que o Usuário possa acessar a plataforma on-line do Brasil Mais Bolsas, é necessário concordar expressamente com esta Política de Privacidade e os Termos de Uso.</p>

          <h2>SERVIÇOS DISPONIBILIZADOS PELO BRASIL MAIS BOLSAS:</h2>

          <p>O Brasil Mais Bolsas coordenará os processos de inscrição, seleção, pré-aprovação, aprovação, contratação e renovação das bolsas de estudo concedidas pelas Instituições de Ensino credenciadas.</p>

          <p>Ao formalizar o contrato de fornecimento de bolsa, o Usuário deverá fornecer as seguintes informações: nome completo do futuro cliente, e-mail, Cadastro de Pessoa Física, número de telefone e, se o pagamento for realizado com cartão de crédito, os dados do cartão, seu titular e detalhes – neste caso, a veracidade e autorização do titular do cartão são de responsabilidade do Cliente e/ou Usuário, bem como informações de cobrança.</p>

          <p>Portanto, o Usuário concorda em fornecer informações verdadeiras, precisas, atualizadas e completas no formulário de registro, além de manter e atualizar imediatamente essas informações de registro.</p>

          <h2>DADOS PESSOAIS COLETADOS:</h2>

          <p>Primeiramente, é importante esclarecer o que são dados pessoais.</p>

          <p>De acordo com a legislação vigente, dados pessoais são informações que podem identificar ou tornar uma pessoa identificável.</p>

          <p>Portanto, devido ao serviço prestado pelo Brasil Mais Bolsas e com o objetivo de melhorar a experiência do Usuário e/ou Cliente que acessa nosso site, listaremos os dados pessoais tratados em nosso banco de dados.</p>

          <p>O tratamento de dados difere entre Usuários e Clientes. O tratamento de dados para Usuários será menos abrangente, enquanto o tratamento completo dos dados será direcionado aos Clientes, ou seja, aqueles que se cadastram no sistema de bolsas do Brasil Mais Bolsas.</p>

          <p>Dados de navegação – informações relacionadas ao computador ou dispositivo móvel usado pelo Usuário e/ou Cliente no momento do acesso ao site, como endereço de IP, localização geográfica, cidade e estado;</p>

          <p>Dados pessoais – para a confecção do contrato de prestação de serviço, coletamos o nome completo do futuro cliente, bem como e-mail, Cadastro de Pessoa Física, número de telefone e, em caso de pagamento com cartão de crédito, incluem-se o número do cartão de crédito, seu titular e detalhes – neste caso, a veracidade e autorização do titular do cartão são de responsabilidade do Cliente e/ou Usuário, bem como informações de cobrança. O site do Brasil Mais Bolsas não trata dados sensíveis – conforme a legislação.</p>

          <h2>FINALIDADE E BASE LEGAL PARA O TRATAMENTO:</h2>

          <p>A atuação do Brasil Mais Bolsas é pautada pelos princípios da Lei Geral de Proteção de Dados, incluindo transparência, finalidade e necessidade. Portanto, coletamos apenas os dados estritamente necessários para o funcionamento do site e para melhorar a experiência do Usuário e/ou Cliente, bem como para cumprir as obrigações decorrentes dos contratos de bolsas de estudo.</p>

          <p>Dessa forma, o tratamento de dados dos nossos Usuários e/ou Clientes tem as seguintes finalidades:</p>

          <p>Melhorar a experiência de uso do site do Brasil Mais Bolsas e fornecer opções de cursos mais adequados aos Usuários e/ou Clientes;</p>

          <p>Enviar mensagens promocionais e informações sobre novos cursos e atualizações;</p>

          <p>Facilitar o contato com os Usuários durante a escolha do curso e na assinatura do contrato de prestação de serviço;</p>

          <p>Informar os Clientes sobre alterações nos Termos de Uso do Site, na Política de Privacidade e na Política de Cookies;</p>

          <p>Realizar cobranças relacionadas exclusivamente aos valores do serviço contratado com o Brasil Mais Bolsas;</p>

          <p>Gerar análises estatísticas que podem ser compartilhadas com empresas parceiras para fins publicitários, sempre buscando anonimizar ou pseudonimizar os dados dos Usuários e/ou Clientes;</p>

          <p>Informar à Instituição de Ensino sobre a assinatura do contrato de fornecimento de bolsa de estudo, enviando os dados dos Clientes diretamente à instituição parceira;</p>

          <p>A CONTRATADA é responsável por obter, às suas custas e sob sua exclusiva responsabilidade, a autorização prévia por escrito dos titulares de dados pessoais para a transferência de tais dados à CONTRATANTE, com as seguintes finalidades específicas:</p>

          <p>(i) Gestão contábil, fiscal e administrativa para controle de cobranças, pagamentos e acompanhamento das informações mensais para medição da prestação de serviços;</p>

          <p>(ii) Cadastro e matrícula do aluno na instituição de ensino;</p>

          <p>(iii) Gestão de recursos humanos para tomada de medidas necessárias ao cumprimento do contrato de prestação de serviços educacionais;</p>

          <p>(iv) Cumprimento de obrigações legais e obrigatórias.</p>

          <p>Entrar em contato com os Usuários e/ou Clientes para fins de divulgação de peças de marketing pelos meios de contato fornecidos ao Brasil Mais Bolsas.</p>

          <p>Devido ao princípio da necessidade, o Brasil Mais Bolsas adere a todos os requisitos legais para o tratamento dos dados de seus Usuários e/ou Clientes. Portanto, nossa política de tratamento de dados está respaldada nas seguintes bases legais:</p>

          <p>Os dados serão tratados para a execução do contrato ou procedimentos preliminares dos quais o titular dos dados é parte. Essa base legal é regulamentada pelo artigo 7°, inciso V, da Lei Geral de Proteção de Dados;</p>

          <p>Para o exercício regular de direitos em processos judiciais, administrativos ou arbitrais, o tratamento será realizado por um período determinado de 5 (cinco anos) a partir da finalização do contrato de fornecimento de bolsa de estudo entre o Brasil Mais Bolsas e seus Clientes. Essa base legal é regulamentada pelo artigo 7°, inciso VI, da Lei Geral de Proteção de Dados.</p>

          <h2>DIREITOS DOS USUÁRIOS E/OU CLIENTES:</h2>

          <p>O Brasil Mais Bolsas adota práticas rigorosas de segurança para tratar adequadamente e proteger as informações dos nossos Usuários e/ou Clientes, bem como se proteger contra possíveis incidentes, como destruição, perda, alteração, vazamento ou uso indevido de dados de nossa base de dados.</p>

          <p>No entanto, os Usuários e/ou Clientes têm o direito de solicitar acesso às informações mantidas pelo Brasil Mais Bolsas, bem como pedir correções, atualizações ou exclusões de seus dados do nosso banco de dados.</p>

          <p>No caso de revogação total ou parcial das autorizações de tratamento de dados, o Brasil Mais Bolsas se compromete a cumprir todos os pedidos enviados pelo canal de comunicação indicado abaixo, exceto nos casos previstos nas permissões legais descritas no artigo 7° da Lei Geral de Proteção de Dados.</p>

          <p>Os Usuários e/ou Clientes que desejarem revogar ou alterar a autorização concedida para o tratamento de seus dados podem entrar em contato diretamente com o Brasil Mais Bolsas para realizar a alteração ou exclusão dos dados de nossa base de dados.</p>

          <p>Para fazer isso, basta entrar em contato com nossa Central de Atendimento pelo telefone 4007-2020 (capitais e regiões metropolitanas) ou 0800 724 7202 (outras localidades), de segunda a sexta-feira, das 07:40h às 20:20h, e aos sábados, das 08:00h às 14:20h.</p>

          <p>O Brasil Mais Bolsas pode tratar os dados de seus ex-clientes por um período máximo de cinco anos, a partir da conclusão do contrato, que corresponde ao prazo prescricional para ajuizar qualquer tipo de demanda judicial, exigindo que a empresa exerça seu direito de defesa em processos judiciais.</p>

          <h2>PRAZO PARA O TRATAMENTO DE DADOS:</h2>

          <p>Inicialmente, vale ressaltar que o Brasil Mais Bolsas, em conformidade com o princípio da necessidade, realiza tratamento de dados específico para cada tipo de pessoa que acessa nosso site.</p>

          <p>Para Usuários simples do nosso site, ou seja, aqueles que não têm contrato de bolsa de estudos com a empresa, faremos um tratamento menos abrangente de seus dados, seja através da coleta de dados de navegação ou do uso de cookies. Por sua natureza, você tem o direito de solicitar consulta, alteração, complementação ou exclusão de nossos registros, o que pode ser feito através do canal de comunicação acima indicado.</p>

          <p>No caso de nossos Clientes, que voluntariamente fornecem seus dados ao assinar um contrato de bolsa com o Brasil Mais Bolsas, todos os dados necessários para cumprir as obrigações contratuais serão mantidos em nosso banco de dados, independentemente de seu interesse pessoal, de acordo com o artigo 7°, V, da Lei Geral de Proteção de Dados.</p>

          <p>Após a conclusão do contrato de prestação de serviços, devido ao artigo 7°, VI da LGPD, o Brasil Mais Bolsas poderá tratar os dados de seus ex-Clientes por um período máximo de cinco anos a partir da conclusão do contrato, que representa o prazo prescricional para ajuizar qualquer tipo de demanda judicial, o que exigirá que a empresa exerça seu direito de defesa em processos judiciais.</p>

          <h2>NOSSA POLÍTICA DE COOKIES:</h2>

          <p>Os cookies são ferramentas de texto armazenadas no dispositivo do Usuário que têm como objetivo melhorar e personalizar a experiência de uso do site para os Usuários e/ou Clientes.</p>

          <p>O site do Brasil Mais Bolsas utiliza cookies que captam dados de navegação, como geolocalização, nome da cidade e estado, e dados pessoais, desde que expressamente autorizados pelo Usuário e/ou Cliente.</p>

          <p>Ao aceitar esta política de privacidade, o Usuário e/ou Cliente concorda explicitamente com todos os cookies usados no site, que serão detalhados a seguir:</p>

          <p>Cookies de Marketing: São utilizados para exibir publicidade direcionada com base na análise do perfil do Usuário e/ou Cliente. Eles também impedem a exibição repetitiva do mesmo anúncio. Esse tipo de cookie utiliza a base de dados de navegação.</p>

          <p>Cookies de Desempenho: Embora não sejam essenciais, esses cookies auxiliam na navegação do Usuário e/ou Cliente no site do Brasil Mais Bolsas. Por exemplo, reconhecem automaticamente o candidato após o registro, lembram a forma de pagamento selecionada anteriormente e preenchem a senha automaticamente.</p>

          <p>Cookies Necessários: Esses cookies têm como objetivo garantir a plena funcionalidade do site do Brasil Mais Bolsas. Por exemplo, eles identificam o curso e a inscrição do aluno, o Cadastro de Pessoa Física e os dados necessários para a realização da avaliação disciplinar.</p>

          <p>É importante destacar que os Cookies Necessários devem ser autorizados para usar o site. No entanto, o Usuário e/ou Cliente pode, a qualquer momento, bloquear essa funcionalidade nas configurações de seu navegador.</p>

          <h2>UTILIZAÇÃO DOS DADOS:</h2>

          <p>O Brasil Mais Bolsas utiliza as informações coletadas no site para disponibilizar bolsas de estudo em Instituições parceiras do programa e elaborar contratos em caso de contratação.</p>

          <p>Podemos usar esses dados para melhorar nosso site, realizar pesquisas, desenvolver, gerenciar, proteger e aprimorar nossos serviços, enviar informações que possam interessar ao Usuário, informar sobre novos produtos e serviços e estreitar o relacionamento e comunicação entre o Brasil Mais Bolsas e o Usuário.</p>

          <p>Não fornecemos informações pessoais a terceiros sem autorização. As informações serão usadas exclusivamente por colaboradores e parceiros do Brasil Mais Bolsas para auxiliar no processo de contratação da bolsa de estudo.</p>

          <p>Dados genéricos e agregados podem ser fornecidos a anunciantes e parceiros comerciais em relação ao número de pessoas que acessam nosso site.</p>

          <h2>SEGURANÇA:</h2>

          <p>A segurança dos dados de nossos Usuários e/ou Clientes é uma de nossas principais preocupações. Com o objetivo de protegê-los, adotamos uma série de medidas organizacionais e tecnológicas para garantir a integridade de nossa política de proteção de dados.</p>

          <p>O Brasil Mais Bolsas cumpre os níveis legalmente exigidos de segurança na proteção de dados e implementa todos os meios e medidas adicionais para evitar a perda, uso indevido, alteração, acesso não autorizado ou apropriação indevida dos dados pessoais coletados.</p>

          <p>Especificamente, o Brasil Mais Bolsas utiliza o sistema de segurança SSL (Secure Socket Layer), que, por meio de sistemas de certificação, verifica a autenticidade da página da web do Brasil Mais Bolsas e garante a integridade e confidencialidade dos dados durante a transmissão. No entanto, o Usuário deve estar ciente de que as medidas de segurança da Internet não são completamente infalíveis.</p>

          <p>Portanto, o Brasil Mais Bolsas não pode garantir que nossos sistemas de segurança não sejam acessados por pessoas não autorizadas. Portanto, o Brasil Mais Bolsas não se responsabiliza por acessos não autorizados a dados pessoais do Usuário, exceto nos casos previstos nas permissões legais descritas na Lei Geral de Proteção de Dados.</p>

          <h2>ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE:</h2>

          <p>O Brasil Mais Bolsas se reserva o direito de alterar a política de privacidade a qualquer momento. No entanto, qualquer alteração substancial na política de privacidade será comunicada aos Usuários e/ou Clientes por meio do site ou por e-mail.</p>

          <h2>INFORMAÇÕES DE CONTATO:</h2>

          <p>Para obter mais informações sobre esta política de privacidade ou para entrar em contato com o Brasil Mais Bolsas sobre qualquer questão relacionada ao tratamento de dados pessoais, entre em contato conosco:</p>

          <p>Brasil Mais Bolsas</p>

          <p><i className="fab wpp-color fa-whatsapp fa-2x"></i><a href="https://wa.me/558197112781" target="_blank" rel="noopener noreferrer" className="ml-2 font-weight-bold wpp-color wpp-text">Envie uma mensagem</a></p>

          <p>Horário de atendimento: de segunda a sexta-feira, das 08:00h às 18:00h, e aos sábados, das 09:00h às 12:00h.</p>

        </div>

        <Footer />
      </div>
    );
  }
};