import React from 'react';
import Api from './Api';

import { Redirect } from 'react-router-dom';

export function isAuthenticated() {
    if (!localStorage.getItem('logged') || !localStorage.getItem('user-name')) return false;
    return Api.get('/user/details/profile').then(response => {
        return true;
    }).catch(error => {
        return false;
    })
}

export async function authenticate(username, password, redirectTo, props) {
    try {
        let response = await Api.post('/auth', { username, password });
        localStorage.setItem('user-name', response.data.user);
        localStorage.setItem('user-token', response.data.token);
        localStorage.setItem('logged', true);
        console.log(response.data)
        return <Redirect to={{ pathname: redirectTo }} />
    } catch (error) {
        throw (error);
    }
}