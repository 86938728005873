import VMasker from 'vanilla-masker';

export function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
}

export function parseMoney(value = 0) {
    return "R$" + VMasker.toMoney((parseFloat(value) || 0).toFixed(2))
}

export function getShift(shift = 'Não definido') {
    shift = shift.toLowerCase();
    if (shift === 'matutino') return 'Manhã';
    if (shift === 'vespertino') return 'Tarde';
    if (shift === 'noturno') return 'Noite';
    if (shift === 'integral') return 'Integral';
    if (shift === 'segundas-manha') return 'Segundas - (Manhã)';
    if (shift === 'segundas-noite') return 'Segundas - (Noite)';
    if (shift === 'tercas-noite') return 'Terças - (Noite)';
    if (shift === 'tercas-manha') return 'Terças - (Manhã)';
    if (shift === 'tercas-tarde') return 'Terças - (Tarde)';
    if (shift === 'tercas-quintas-noite') return 'Terças e quintas - (Noite)';
    if (shift === 'quartas-sextas-noite') return 'Quartas e sextas - (Noite)';
    if (shift === 'quintas-manha') return 'Quintas - (Manhã)';
    if (shift === 'quintas-tarde') return 'Quintas - (Tarde)';
    if (shift === 'quintas-noite') return 'Quintas - (Noite)';
    if (shift === 'sextas-manha') return 'Sextas - (Manhã)';
    if (shift === 'sextas-tarde') return 'Sextas - (Tarde)';
    if (shift === 'sextas-noite') return 'Sextas - (Noite)';
    if (shift === 'quartas-manha') return 'Quartas - (Manhã)';
    if (shift === 'quartas-tarde') return 'Quartas - (Tarde)';
    if (shift === 'quartas-noite') return 'Quartas - (Noite)';
    if (shift === 'quartas-manha-tarde') return 'Quartas - (Manhã e tarde)';
    if (shift === 'segundas-tercas-manha') return 'Segundas e terças - (Manhã)';
    if (shift === 'segundas-tercas-tarde') return 'Segundas e terças - (Tarde)';
    if (shift === 'segundas-tercas-noite') return 'Segundas e terças - (Noite)';
    if (shift === 'segundas-tercas-manha-tarde') return 'Segundas e terças - (Manhã e tarde)';
    if (shift === 'sabados-manha') return 'Sábados - (Manhã)';
    if (shift === 'sabados-tarde') return 'Sábados - (Tarde)';
    if (shift === 'sabados-manha-tarde') return 'Sábados - (Manhã e tarde)';
    return shift;
}

export function CPFValidate(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
}