import React, { Component } from 'react';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Api from '../../Services/Api';

import './index.css'
import { toast } from 'react-toastify';
import moment from 'moment';

export default class Sellers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            users: [],
            seller: null,
            loading: true,
            authenticated: false
        }
    }


    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var formData = new FormData(document.getElementById('seller-login-form'));
            var creditCardData = {};
            formData.forEach((value, key) => { creditCardData[key] = value });
            let request = await Api.post('/seller/login', creditCardData);
            let seller = request.data.seller;
            this.setState({ authenticated: true, seller });
        } catch (error) {
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            console.log(error.response);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="d-flex flex-column justify-content-center align-items-center page-content p-1 p-sm-4">
                    {
                        this.state.authenticated
                            ?
                            <div className="text-center">
                                <h4>Bem vindo, {this.state.seller.name}.</h4>
                                <div>Você foi responsável pela contratação de <h3 className="d-inline">{this.state.seller.institutions.length}</h3> instituições!</div>
                                <div className="mt-4">
                                    <h5>Lista:</h5>
                                    {this.state.seller.institutions.map(institution => {
                                        return <span className="d-block">{institution.name} - {moment(institution.createdAt).format('DD/MM/YYYY')} - {institution.students_count} alunos</span>
                                    })}
                                </div>
                            </div>
                            :
                            <div className="col-12 col-md-4">
                                <form id="seller-login-form" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Sua senha"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn-primary btn-lg">
                                            <span>Entrar</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
