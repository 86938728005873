import React, { Component } from 'react';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Loader from 'react-loading-components';
import Api from '../../Services/Api';

import './index.css'
import { toast } from 'react-toastify';

export default class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedType: '',
            firstNumber: '81 3137-1484',
            secondNumber: '81 3137-2487',
            email: '',
            loading: false,
        }
    }

    changeContactType = (e) => {
        switch (e.target.value) {
            case 'queroSerAluno':
                this.setState({ email: 'contato@brasilmaisbolsas.com.br' })
                break;
            default:
                this.setState({ email: 'comercial@brasilmaisbolsas.com.br' })
                break;
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formData = new FormData(document.getElementById('contactForm'));
        var contactData = {};
        formData.forEach((value, key) => { contactData[key] = value });
        contactData.targetEmail = this.state.email;
        try {
            await Api.post('/contact', contactData);
            toast.success('Sua mensagem foi enviada.')
            document.getElementById("contactForm").reset();
        } catch (error) {
            toast.error('Erro ao enviar mensagem, tente outra forma de contat.')
            console.log(error.response);
        }
        this.setState({ loading: false })
    }

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="container-fluid contact-content p-1 p-md-4">
                    <div className="row">
                        <div className="col-12">
                            <h2>Entre em contato</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 mb-3 mb-md-0">
                            <form id="contactForm" onSubmit={this.handleSubmit}>
                                <select className="form-control mb-3" defaultValue={this.state.selectedType} onChange={this.changeContactType} name="type" required>
                                    <option disabled value="">Tipo de contato</option>
                                    <option value="souAluno">Sou aluno</option>
                                    <option value="queroSerAluno">Quero ser aluno</option>
                                    <option value="souParceiro">Sou parceiro</option>
                                </select>
                                <input type="text" className="form-control mb-3" id="name" name="name" placeholder="Seu nome" required />
                                <input type="email" className="form-control mb-3" id="mail" name="email" placeholder="Seu e-mail" required />
                                <input type="text" className="form-control mb-3" id="phone" name="phone" placeholder="DDD + número" required />
                                <input type="text" className="form-control mb-3" id="subject" name="subject" placeholder="Assunto" required />
                                <textarea name="message" id="message" className="form-control mb-3" placeholder="Como podemos ajudar?" required ></textarea>
                                {this.state.loading ?
                                    <Loader type='tail_spin' width={50} height={50} fill='#14496b' />
                                    :
                                    <button type="submit" value="send" className="btn btn-secondary btn-send-contact text-white">Enviar mensagem</button>
                                }
                            </form>
                        </div>
                        <div className="col-md-5">
                            <h2 className="section-title">Fale com o <br />Brasil Mais Bolsas</h2>
                            <a href={`tel:${this.state.firstNumber.replace(/\D+/g, '')}`} className="text-color h5 d-block"><strong>{this.state.firstNumber}</strong></a>
                            <a href={`tel:${this.state.secondNumber.replace(/\D+/g, '')}`} className="text-color h5 d-block"><strong>{this.state.secondNumber}</strong></a>
                            <a href="https://wa.me/558197112781" className="text-whatsapp h5 d-block" ><strong className="wpp-color">Entre em contato através whatsapp</strong></a>
                            <a href="mailto:atendimento@brasilmaisbolsas.com.br" className="mb-5 text-color h5 d-block contact-email">{this.state.email}</a>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
