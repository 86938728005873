import React, { Component } from 'react';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import SubHeader from '../../Components/SubHeader';

import './index.css'

export default class HowItWorks extends Component {

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <SubHeader title="Como Funciona" />
                <div className="row d-flex justify-content-center align-items-center page-content p-1 p-md-3 p-lg-5 mx-auto mx-sm-0">
                    <div className="row border-bottom hover-shadow w-100 mb-4">
                        <div className="col-md-1 bg-secondary d-flex align-items-center justify-content-center">
                            <i className="ti-search p-4 feature-icon text-white"></i>
                        </div>
                        <div className="col-md-11 p-3">
                            <h4>Encontre seu curso</h4>
                            <p>
                                Encontre o Curso, Série ou a Instituição de Ensino onde deseja estudar.
                                O Brasil Mais Bolsas oferece descontos em cursos de Graduação, Pós-Graduação, Educação Básica, Cursos Técnicos, Cursos Profissionalizantes, Idiomas, EJA, Preparatório para Concursos e Pré-Vestibular/ENEM, pois acreditamos que a educação de qualidade deve estar ao alcance de todos.
                            </p>
                        </div>
                    </div>
                    <div className="row border-bottom hover-shadow w-100 mb-4">
                        <div className="col-md-1 bg-secondary d-flex align-items-center justify-content-center">
                            <i className="ti-money p-4 feature-icon text-white"></i>
                        </div>
                        <div className="col-md-11 p-3">
                            <h4>Contrate a sua Bolsa</h4>
                            <p>
                                Selecione uma bolsa disponível e contrate direto pelo site, pagando com boleto ou cartão de crédito à vista ou parcelado. O valor corresponde ao valor integral da primeira mensalidade do seu curso ou série.
                            </p>
                        </div>
                    </div>
                    <div className="row border-bottom hover-shadow w-100 mb-4">
                        <div className="col-md-1 bg-secondary d-flex align-items-center justify-content-center">
                            <i className="ti-check-box p-4 feature-icon text-white"></i>
                        </div>
                        <div className="col-md-11 p-3">
                            <h4>Imprima seu Cupom de Aprovação</h4>
                            <p>
                                Dirija-se à instituição de Ensino com o Cupom de Aprovação para se matricular no curso ou série desejado.
                            </p>
                        </div>
                    </div>
                    <div className="row border-bottom hover-shadow w-100 mb-4">
                        <div className="col-md-1 bg-secondary d-flex align-items-center justify-content-center">
                            <i className="ti-help p-4 feature-icon text-white"></i>
                        </div>
                        <div className="col-md-11 p-3">
                            <h4>Tire suas dúvidas</h4>
                            <p>
                                Você tem dúvida sobre o programa? Temos uma série de perguntas respondidas em nosso FAQ (clique aqui) para lhe ajudar. Se ainda assim precisar falar com a gente, use um dos nossos canais:<br />
                                <b>81 3137-1484<br />
                                    81 3137-2487</b><br />
                                <a href="https://wa.me/558197112781">Fale pelo Whatsapp</a>
                            </p>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
