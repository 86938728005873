import React, { Component } from 'react';
import qs from 'query-string';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Loading from '../../Components/Loading';
import HtmlParser from 'html-react-parser';
import { parseMoney, getShift } from '../../Util/Functions';

import Api from '../../Services/Api';
import './index.css'

export default class Buy extends Component {

    constructor(props) {
        super(props)

        this.state = {
            scholarship: {},
            loading: true
        }
    }

    async componentDidMount() {
        this.getData();
    }

    getData = async () => {
        let query = qs.parse(this.props.location.search);
        try {
            let scholarshipRequest = await Api.get('/scholarship/' + query.bolsa);
            this.setState({ loading: false, scholarship: scholarshipRequest.data.scholarship });
        } catch (error) {
            console.log(error);
        }
    }

    getDurationType = (durationType) => {
        switch (durationType) {
            case 'days':
                return 'dias';
            case 'months':
                return 'meses';
            case 'years':
                return 'anos';
            default:
                break;
        }
    }

    render() {
        if (this.state.loading) return <div className="container-fluid"><Header /><Loading /><Footer /></div>
        return (
            <div className="container-fluid">
                <Header />
                <div className="row d-flex px-3 mx-lg-5 px-lg-5 justify-content-between mb-4">
                    <div className="col-md-10 mt-4 mb-3">
                        <h3>Inscrição</h3>
                    </div>
                    <div className="col-md-9 pr-md-5 mb-3">
                        <div className="row">
                            <div className="col-12 p-0 info-box mb-3">
                                <div className="scholarship-details-header bg-primary p-1 px-2">
                                    <span className="text-white">Detalhes da sua bolsa</span>
                                </div>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-md-8 d-flex align-items-center">
                                            <table>
                                                <tr>
                                                    <td className="pr-3">Curso / Série:</td>
                                                    <td><b>{this.state.scholarship.course.name}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Instituição:</td>
                                                    <td><b>{this.state.scholarship.institution.name}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Turno:</td>
                                                    <td><b className="capitalize">{getShift(this.state.scholarship.shift)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Modalidade:</td>
                                                    <td><b className="capitalize">{this.state.scholarship.type}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Bolsa de:</td>
                                                    <td><b className="text-value text-secondary">{this.state.scholarship.discount}%</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Mensalidade:</td>
                                                    <td>
                                                        <b className="text-value text-secondary">{parseMoney(this.state.scholarship.final_price)}</b>
                                                        <strike className="ml-3 scholarship-full-price">{parseMoney(this.state.scholarship.full_price)}</strike>
                                                    </td>
                                                </tr>
                                                {
                                                    this.state.scholarship.course.category_id !== 3 ?
                                                        <tr>
                                                            <td>Duração do curso:</td>
                                                            <td><b className="capitalize">{this.state.scholarship.course.duration + ' ' + this.getDurationType(this.state.scholarship.course.duration_type)}</b></td>
                                                        </tr>
                                                        : null
                                                }
                                                <tr>
                                                    {/* <td colSpan="2" className="py-1"><i className="fa fa-gift mr-1 text-secondary"></i><b>Aproveite seu desconto de 50% na matrícula!</b></td> */}
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <span className="scholarship-details-description-title d-block">Sobre o curso {this.state.scholarship.course.name}</span >
                                {HtmlParser(`${this.state.scholarship.course_description}`)}
                            </div>
                            <div className="col-md-6">
                                <span className="scholarship-details-description-title d-block">Sobre a instituição {this.state.scholarship.institution.name}</span >
                                {HtmlParser(`${this.state.scholarship.institution.description}`)}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 p-0 info-box">
                        <div className="scholarship-info-header bg-primary p-2 px-2">
                            <span className="text-white">Informações importantes</span>
                        </div>
                        <div className="p-3">

                            <span className="d-block"><b>Quem pode ganhar a bolsa?</b></span>
                            <span className="d-block"><b>•</b> Novos alunos ingressantes.</span>
                            <span className="d-block"><b>Quem não pode ganhar a bolsa?</b></span>
                            <span className="d-block"><b>•</b> Alunos que tenham vínculo com a instituição de ensino;</span>
                            <span className="d-block"><b>•</b> Alunos com menos de 06 (seis) meses sem estudar, independente da instituição que estavam matriculados.</span>
                            <span className="d-block"><b>Conheça as características da bolsa:</b></span>
                            <span className="d-block"><b>•</b> O percentual de desconto adquirido por meio do programa é válido até o final do curso, independente de reajustes feitos pela instituição nos valores das mensalidades;</span>
                            <span className="d-block"><b>•</b> O valor relativo ao curso oferecido pode ser alterado sem aviso prévio. Caso haja divergência, predomina o informado pela instituição de ensino;</span>
                            <span className="d-block"><b>•</b> A bolsa não é válida para disciplinas reprovadas;</span>
                            <span className="d-block"><b>•</b> A formação de turmas nos cursos ofertados depende do número de vagas existentes no ato da matrícula e é definido pela instituição.</span>
                            <span className="d-block"><b>Em que caso o aluno pode perder a bolsa?</b></span>
                            <span className="d-block"><b>•</b> Ao trancar ou cancelar a matrícula;</span>
                            <span className="d-block"><b>•</b> Transferir o curso, turno ou instituição;</span>
                            <span className="d-block"><b>•</b> Caso não efetue o pagamento na data do vencimento ou esteja inadimplente, o desconto será suspenso até a sua regularização.</span>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
