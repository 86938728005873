import React, { Component } from 'react';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Loading from '../../Components/Loading';
import Api from '../../Services/Api';
import { Collapse } from 'react-bootstrap';

import './index.css'
import { toast } from 'react-toastify';

export default class Faq extends Component {
    constructor(props) {
        super(props)

        this.state = {
            questions: [],
            collapses: [],
            loading: true
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        try {
            let request = await Api.get('/faq');
            let collapses = [];
            request.data.faq.forEach(question => {
                collapses[question.id] = false;
            });
            this.setState({ questions: request.data.faq, loading: false, collapses });

        } catch (error) {
            console.log(error);
            toast.error('Erro na solicitação');
        }
    }

    toggleQuestion = (questionId) => {
        let collapses = this.state.collapses;
        collapses[questionId] = !collapses[questionId];
        this.setState({ collapses });
    }

    render() {
        if (this.state.loading) return <div className="container-fluid"><Header /><Loading /><Footer /></div>
        return (
            <div className="container-fluid">
                <Header />
                <div className="d-flex flex-column justify-content-center align-items-center page-content p-1 p-sm-4">
                    {this.state.questions.map(question => {
                        return (
                            <div className="row mb-4 question-container border hover-shadow" key={question.id}>
                                <div className="col-12 question-header bg-light text-center p-4">
                                    <button className="btn btn-link" onClick={(e) => { this.toggleQuestion(question.id) }}>{question.question}</button>
                                </div>
                                <Collapse in={this.state.collapses[question.id]}>
                                    <div className="col-12 p-3 text-center">
                                        {`${question.answer}`}
                                    </div>
                                </Collapse>
                            </div>
                        )
                    })}
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
