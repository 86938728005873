import React, { useEffect, useState } from 'react';
import Routes from './routes';
import { CookiesProvider, useCookies } from 'react-cookie';
import CookieConsentModal from './Views/CookieConsentModal';

const App = () => {
  const [cookies, setCookie] = useCookies(['cookieAccepted']);
  const [showCookieConsent, setShowCookieConsent] = useState(!cookies.cookieAccepted);

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && window.location.protocol === 'http:') {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  }, []);

  const handleAcceptCookies = () => {
    setCookie('cookieAccepted', true, { path: '/' });
    setShowCookieConsent(false);
  };

  return (
    <CookiesProvider>
      <Routes />
      {showCookieConsent && (
        <CookieConsentModal isOpen={showCookieConsent} onAccept={handleAcceptCookies} />
      )}
    </CookiesProvider>
  );
};

export default App;
