import React from 'react';
import './index.css'

const CookieConsentModal = ({ isOpen, onAccept }) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0)', // Fundo transparente
    },
    content: {
      border: 'none',
      background: 'transparent', // Conteúdo do modal transparente
      boxShadow: 'none',
      padding: '0',
    },
  };

  return (
    <div className="aviso-cookie">
      <div className="aviso-cookie-box">
        <div className="aviso-cookie-texto">
          Utilizamos cookies. <a href="/termos-uso">Política de Privacidade</a>.
        </div>
        <button onClick={onAccept} className="btn btn-primary text-white font-weight-bold"> Concordo </button>
      </div>
    </div>
  );
};

export default CookieConsentModal;
