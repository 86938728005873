import React, { Component } from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Loader from 'react-loading-components';
import Api from '../../Services/Api';
import { toast } from 'react-toastify';
import './index.css';
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';

export default class Indications extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            mail: '',
            city: '',
            telefone: "",
            loading: false,
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
      
        try {
          var formData = new FormData(e.target);
          var indicationsData = {};
          formData.forEach((value, key) => { indicationsData[key] = value.toUpperCase() });
      
          await Api.post('/indications', indicationsData);
      
          toast.success('Sua indicação foi enviada.');
      
          this.setState({
            name: '',
            mail: '',
            city: '',
            telefone: "",
            loading: false,
          });

          $('#city-select').val(null).trigger('change');
        } catch (error) {
          if(error.response.data.message === 'duplicated-data') {
            toast.error('E-mail ou telefone já cadastrados.');
          } else {
            toast.error('Erro ao enviar indicação, tente outra forma de contato.');
          }
          this.setState({ loading: false });
        }
    };
      
    startSelect2 = () => {
        $.fn.select2.defaults.set("language", {
            noResults: function () { return "Sem resultados" },
            noMatches: function () { return "Nenhum resultado encontrado"; },
            inputTooShort: function (input, min) { return 'Digite no mínimo 2 caracteres.' },
            loadMore: function (pageNumber) { return "Buscando..."; },
            searching: function () { return "Buscando..."; }
        });
    
        const self = this; // Captura o contexto da classe para uso dentro da função assíncrona
    
        $('.city-select').select2({
            placeholder: "CIDADE",
            minimumInputLength: 2,
            theme: "bootstrap4",
            allowClear: true,
            ajax: {
                cache: true,
                url: function (params) {
                    return `https://servicodados.ibge.gov.br/api/v1/localidades/municipios?nome=${params.term}`;
                },
                dataType: 'json',
                delay: 250,
                processResults: function (data) {
                    return {
                        results: $.map(data, function (city) {
                            return {
                                text: `${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla}`.toUpperCase(),
                                id: `${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla}`.toUpperCase()
                            }
                        }),
                    };
                }
            }
        });
    }    

    handlePhoneChange = (event) => {
        const telefoneValue = event.target.value;
        this.setState({
          telefone: telefoneValue,
        });
    };

    componentDidMount() {
        this.startSelect2();
    }
    
    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="container-fluid indications-content p-1 p-md-4">
                    <div className="row">
                        <div className="col-12">
                            <h2>Indique um amigo</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3 mb-md-0">
                            <form id="indicationsForm" onSubmit={this.handleSubmit}>
                                <input type="text" className="form-control mb-3" id="name" name="name" placeholder="Nome do indicado" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} required />
                                <input type="email" className="form-control mb-3" id="mail" name="email" placeholder="E-mail do indicado" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })} required />
                                <div className="form-group">
                                    <select className="form-control mb-3 city-select" name="city" id="city-select" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} required />
                                </div>
                                <TelefoneBrasileiroInput type="tel" className="form-control mb-3" id="cell" name="cell" placeholder="DDD + número" value={this.state.telefone} onChange={this.handlePhoneChange} temDDD required />
                                {this.state.loading ?
                                    <Loader type='tail_spin' width={50} height={50} fill='#14496b' />
                                    :
                                    <button type="submit" value="send" className="btn btn-secondary btn-send-indications text-white">Indicar amigo</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

