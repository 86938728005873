import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './scss/custom.scss'; //Bootstrap with custom values
import './Libs/fontawesome/css/all.css';
import './Libs/themify-icons/themify-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import './global.css';


ReactDOM.render(<App />, document.getElementById('root'));