import React, { Component } from 'react';
import qs from 'query-string';
import Loader from 'react-loading-components';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Api from '../../Services/Api';

import './index.css';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            loading: false,
            error: false,
            sent: false
        }
    }

    componentDidMount() {
        let query = qs.parse(this.props.location.search);
        if (query.user) {
            this.setState({ email: query.user })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, error: false })
        try {
            let emailRequest = await Api.post('/auth/forgot_password', { email: this.state.email });
            let emailResponse = emailRequest.data;
            if (emailResponse.ok && emailResponse.ok === true) {
                this.setState({ sent: true, loading: false, error: false })
            }
        } catch (error) {
            console.log(error.response);
            this.setState({ loading: false, error: true })
        }

    }

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="row main-container">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        {this.state.sent ?
                            <div>
                                <h3>Pronto</h3>
                                <span>Enviamos um email para você contendo um link para a redefinição da sua senha. Lembre-se de verificar sua caixa de spam.</span>
                            </div>
                            :
                            <form className="row p-3 d-flex flex-column login-form" onSubmit={this.handleSubmit}>
                                <h3 className="d-block mb-3">Recuperar senha</h3>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        value={this.state.email}
                                        onChange={(e) => { this.setState({ email: e.target.value }) }}
                                    />
                                </div>
                                {this.state.error
                                    ?
                                    <div className="form-group">
                                        <span className="text-danger">Email não encontrado</span>
                                    </div>
                                    :
                                    null
                                }
                                <div className="row text-center d-flex align-items-center">
                                    <div className="col-md-5">
                                        {this.state.loading ?
                                            <Loader type='tail_spin' width={40} height={40} fill='#14496b' />
                                            :
                                            <button type="submit" className="btn btn-secondary btn-block text-white">ENVIAR</button>
                                        }

                                    </div>
                                </div>
                            </form>}
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
