/* eslint-disable */
import React, { Component } from 'react';


import './index.css';

export default class Contract extends Component {

    render() {
        return (
            <div className="container mt-3 contract-container">
                    <div>
                        <div className="row mb-5">
                            <div className="col-4 d-flex align-items-center justify-content-center">
                                <img width="200" height="91" src={require('../../Images/brasil-logo.webp').default} alt="logo" />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <span className="text-center"><b>CONTRATO DE BOLSA DE ESTUDO DO PROGRAMA BRASIL MAIS BOLSAS – EDUCAÇÃO BÁSICA</b></span>
                            </div>
                        </div>

                        <p><h5>IDENTIFICAÇÃO DAS PARTES CONTRATANTES</h5></p>
                        <p><b>(I) INSTITUIÇÃO DE ENSINO CONCEDENTE</b></p>
                        <p><b>XXXXXXXXXXX</b> , estabelecida em <b>XXXXXXXXXXXX</b>, na cidade de <b>XX</b>, CNPJ <b>XXXXXXXXXXX</b>, doravante simplesmente denominada <b>“ESCOLA”</b>, na situação de CONTRATADA. </p>

                        <p><b>(II) ALUNO BENEFICIADO </b></p>
                        <p><b>XXXXXXXXXXX</b>, doravante denominado <b>“ALUNO BENEFICIADO”</b>, na situação de CONTRATANTE.</p>

                        <p><b>(III) RESPONSÁVEL LEGAL</b></p>

                        <p><b>XXXXXXXX</b>, como contratante, RG <b>XXXXXXX</b>, ORGÃO <b>XXXXXXXX</b>, CPF <b>XXXXXXXXXX</b>, residente na cidade de <b>XXXXXXXX</b>, <b>XXXXXXXXXXX</b> ,na <b>XXXXXXXXXXX</b>, nº <b>XXXXXXXXX</b> - CEP <b>XXXXXXXXXXX</b>, Bairro: <b>XXXXXXXXXXXX</b>, doravante denominado <b>“RESPONSÁVEL LEGAL”.</b></p>

                        <p><b>(IV) INTERVENIENTE ANUENTE</b></p>
                        <p> <strong>BRASIL MAIS ESCOLAS ADMINISTRAÇÃO DE PROGRAMA EDUCACIONAL LTDA</strong>, pessoa jurídica de direito privado, inscrita no CNPJ sob n.º 33.076.484/0001-01, estabelecida na cidade de <strong>Caruaru</strong>, Rua Rio Formoso, 05 – Boa Vista - CEP 55.038-560, doravante simplesmente denominada “BRASIL MAIS BOLSAS”, neste ato figurante como <b>INTERVENIENTE ANUENTE;</b></p>
                        <p>As partes acima qualificadas celebram entre si, justa e acertadamente, o presente Contrato de Prestação de Serviços que se regerá pelas cláusulas seguintes, com termos e condições de preço e forma, entre outras regras formalizadas por este ato.</p>
                        <p><h5>PRELIMINARMENTE:</h5></p>
                        <p><h5>DOS CONSIDERANDOS</h5></p>
                        <p>Considerando que os “CONSIDERANDOS” são recursos bem vindos aos contratos regidos pelo vigente Código Civil Brasileiro, a elucidar os motivos da contratação, fatos e intenções, de modo a enaltecer a existência do princípio da boa-fé, basilar e essencial à formação dos contratos para o ordenamento jurídico brasileiro, e que, dá suporte à melhor interpretação contratual, auxiliando na prevenção e solução positiva de eventuais conflitos; <b>(Da importância dos “CONSIDERANDOS”)</b></p>
                        <p>CONSIDERANDO que a INSTITUIÇÃO DE ENSINO firmara Contrato de Prestação de Serviços com o BRASIL MAIS BOLSAS, a fim de disponibilizar determinado número de vagas para cursos e/ou séries, por si ministrados, para o programa de Bolsas de Estudo, gerenciado pelo BRASIL MAIS BOLSAS; <b>(Dos motivos da contratação)</b></p>
                        <p>CONSIDERANDO que, na prática, <u>vaqas, cursos e/ou séries podem sofrer alterações durante o período do ano letivo, devido variados motivos, de ordem legal, convencional de regramento interno da INSTITUIÇÃO DE ENSINO, perda de ano letivo pelo aluno, morte, etc, motivos de ordem legal/jurídica ou natural; <b>(Da realidade fática acerca de estado de vagas, cursos e séries)</b></u></p>
                        <p>CONSIDERANDO que o ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL no ato da inscrição para participação da seleção da bolsa do programa BRASIL MAIS BOLSAS confirma previamente junto à INSTITUIÇÃO DE ENSINO o valor da mensalidade do curso ou série e aceita as condições do BRASIL MAIS BOLSAS, tomando conhecimento do Regulamento e das Cláusulas Contratuais que regem o referido programa, sendo-lhe disponibilizados os referidos instrumentos, quando da finalização da sua inscrição no site <u>www.brasilmaisbolsas.com.br</u>; <b>(Do fornecimento/acesso de/à informações ao candidato à bolsa)</b></p>
                        <p>CONSIDERANDO que o ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL é conhecedor de que o Programa de Bolsas BRASIL MAIS BOLSAS é um programa de INCLUSÃO EDUCACIONAL, e que por isto devem atender as exigências especificadas neste instrumento e no Regulamento disponibilizado na INSTITUIÇÃO DE ENSINO ou através do site <u>www.brasilmaisbolsas.com.br</u>, responsabilizando-se por todas as informações prestadas no site do BRASIL MAIS BOLSAS; <b>(Do conhecimento do aluno bolsista quanto ao objeto e condições do Programa de Bolsas, bem como de suas responsabilidades perante o programa)</b></p>
                        <p>CONSIDERANDO que o ALUNO BENEFICIADO, aprovado no processo seletivo e/ou na análise documental da INSTITUIÇÃO DE ENSINO, assim como o seu RESPONSÁVEL LEGAL, declaram reunir os critérios previstos no Regulamento do Programa de Bolsas de Estudos do BRASIL MAIS BOLSAS, principalmente que <u>O ALUNO BENEFICIADO NÃO ESTÁ OU NÃO ESTEVE MATRICULADO NA INSTITUICÃO DE ENSINO HÁ PELO MENOS UM ANO, EXCETO PARA OS CURSOS DE PÓS GRADUACÃO</u> habilitando-se dessa forma a participar do programa de Bolsas de Estudos da INSTITUIÇÃO DE ENSINO, gerenciado pelo BRASIL MAIS BOLSAS; <b>(Da declaração do estado de habilitação do aluno beneficiado)</b></p>

                        <p>
                            <ol>
                                <li>
                                    <strong><h5>Cláusula Primeira – DO OBJETO</h5></strong>
                                    <ol>
                                        <li>É objeto do presente contrato a concessão condicional de Bolsas de Estudos, da CONTRATADA ao CONTRATANTE, através da INTERVENIENTE ANUENTE.</li>
                                        <li>
                                            Por intermédio do presente instrumento de Contrato, a INSTITUIÇÃO DE ENSINO concede ao ALUNO BENEFICIADO, participante de programa BRASIL MAIS BOLSAS e aprovado em processo seletivo e/ou análise documental da INSTITUIÇÃO DE ENSINO uma bolsa de estudos para <strong>XXXXXXX</strong>, no <strong>Período Letivo de XXXXX</strong>, <strong>no Turno XXXXX, </strong>sendo:
                                            <p className="mt-2 ml-4">a) 100% (cem por cento) de bolsa referente ao valor da taxa que o ALUNO BENEFICIADO paga quando da sua matrícula ou “primeira mensalidade” na INSTITUIÇÃO DE ENSINO, tendo em vista que o mesmo já efetuou pagamento da Taxa de Adesão ao BRASIL MAIS BOLSAS;</p>
                                            <p className="mt-2 ml-4">b) A concessão do benefício <u>fica restrita obrigatoriamente à efetivação da matrícula para o período de XXXX</u>, ainda que, para os cursos de GRADUAÇÃO, o edital do VESTIBULAR da INSTITUIÇÃO DE ENSINO contemple período para matrícula posterior ao referido período </p>
                                            <p className="mt-2 ml-4">c) O benefício, acima descrito, devido quando do ingresso do ALUNO BENEFICIADO na INSTITUIÇÃO DE ENSINO;</p>
                                            <p className="mt-2 ml-4">d) O valor de mensalidade diferente do valor da matrícula, o benefício de 100% incidirá sobre o maior valor dentre eles;</p>
                                            <p className="mt-2 ml-4">e) Garantida a inclusão do benefício às REMATRÍCULAS de cada período letivo subsequente ao ingresso do ALUNO BENEFICIADO na INSTITUIÇÃO DE ENSINO, desde que correspondam à curso, série, ou mensalidades sequenciais, de uma mesma etapa de formação; </p>
                                            <p className="mt-2 ml-4">f) Assegurado o direito da INSTITUIÇÃO DE ENSINO de ajuste dos valores das mensalidades anualmente, na forma da Legislação em vigor.</p>
                                        </li>
                                        <li>O percentual da Bolsa indicado na alínea “a”, da Cláusula 1.2, <b>incidirá exclusivamente sobre o valor bruto da mensalidade do Curso ou Série</b>, não sendo cumulativo com o desconto de pontualidade nem com qualquer outro benefício que seja concedido pela INSTITUIÇÃO DE ENSINO aos demais alunos não oriundos do BRASIL MAIS BOLSAS. </li>
                                        <li>Fica vedada a concessão de BOLSA DE ESTUDOS nos casos de reprovação de disciplinas.</li>
                                        <li>A vedação também se aplica à incidência de BOLSA DE ESTUDOS em taxas escolares variadas a exemplo de encargo para a realização de avaliações extraordinárias como “segunda chamada” e “prova final”, custo com material didático, fardamento, aulas extras curriculares, etc, que serão devidas, em seu preço integral, pelo ALUNO BENEFICIADO. </li>
                                        <li>O pagamento dos valores não alcançados pela BOLSA DE ESTUDOS, concedida neste instrumento, será efetuado diretamente à INSTITUIÇÃO DE ENSINO, no respectivo vencimento, conforme disposição contratual no Contrato de Prestação de Serviços Educacionais, firmado entre a INSTITUIÇÃO DE ENSINO e ALUNO e/ou RESPONSÁVEL LEGAL.</li>
                                        <li><b>O não pagamento de TAXA DE RENOVAÇÃO SEMESTRAL ao BRASIL MAIS BOLSAS, implicará NO CANCELAMENTO AUTOMÁTICO DA BOLSA DE ESTUDOS SEM NECESSIDADE DE AVISO PRÉVIO.</b></li>
                                    </ol>
                                </li>

                                <li className="mt-4">
                                    <p><h5>Cláusula Segunda - Das Obrigações e Responsabilidades do Contratante</h5></p>
                                    <ol>
                                        <li>O ALUNO BENEFICIADO deverá realizar e ser aprovado no processo de avaliação junto à INSTITUIÇÃO DE ENSINO, sempre que exigido.</li>
                                        <li>O ALUNO ou RESPONSÁVEL LEGAL deverá assinar e entregar à INSTITUIÇÃO DE ENSINO o CUPOM DE APROVAÇÃO da bolsa de estudo, disponibilizado no Portal do Aluno através do site <u>www.brasilmaisbolsas.com.br</u>.</li>
                                        <li>O ALUNO BENEFICIADO deverá realizar o pagamento de taxa de adesão ao programa BRASIL MAIS BOLSAS no ato do ingresso à Bolsa de Estudo, através do site <u>www.brasilmaisbolsas.com.br</u>. </li>
                                        <li>O ALUNO BENEFICIADO deverá realizar pagamento ao BRASIL MAIS BOLSAS, referente à Taxa Administrativa, a cada 180 dias (06 meses) após a contratação da BOLSA DE ESTUDOS, até o termino da sua etapa de formação ou até enquanto durar o Contrato com o BRASIL MAIS BOLSAS.</li>
                                        <li>O ALUNO ou RESPONSÁVEL LEGAL deverá efetuar os pagamentos da mensalidade pontualmente até a data do vencimento, estabelecido pela própria INSTITUIÇÃO DE ENSINO.</li>
                                        <li>O ALUNO BENEFICIADO não deve estar ou ter estado matriculado na INSTITUICÃO PE ENSINO há, no mínimo 1 (um) ano, exceto na hipótese de matrículas de Cursos de Pós-Graduação. </li>
                                        <li>O ALUNO ou RESPONSÁVEL LEGAL deverá assinar o contrato de prestação de serviço educacional junto à INSTITUIÇÃO DE ENSINO.</li>
                                        <li>O ALUNO BENEFICIADO deverá manter boa conduta disciplinar, sendo-lhe vedada a prática de qualquer ato contrário à lei ou ao Regimento Interno da INSTITUIÇÃO DE ENSINO, de forma que qualquer infração disciplinar ou legal ensejará na perda total do benefício auferido no Programa de BOLSA DE ESTUDO.</li>
                                        <li>
                                            O ALUNO BENEFICIADO deve permanecer matriculado no curso ou série para qual foi aprovado, não sendo possível, permanecer com o benefício se:
                                            <p className="mt-2 ml-4">a) Não atingir a Média Escolar, a partir dos requisitos mínimos de aproveitamento acadêmico determinado pela INSTITUIÇÃO DE ENSINO;</p>
                                            <p className="mt-2 ml-4">b) Estiver inadimplente com o BRASIL MAIS BOLSAS ou com a INSTITUIÇÃO DE ENSINO;</p>
                                            <p className="mt-2 ml-4">c) Solicitar transferência de turno ainda que na mesma INSTITUIÇÃO DE ENSINO;</p>
                                            <p className="mt-2 ml-4">d) Solicitar transferência para outra Instituição de Ensino; </p>
                                            <p className="mt-2 ml-4">e) Houver trancamento ou desistência de matrícula;</p>
                                        </li>
                                        <li>O ALUNO ou RESPONSÁVEL LEGAL deverá proceder na forma do regulamento do Programa de Bolsas de Estudos e na forma indicada pelo BRASIL MAIS BOLSAS, a renovação do presente Contrato, sob pena de sua rescisão automática e de pleno direito.</li>
                                        <li>O ALUNO ou RESPONSÁVEL LEGAL autoriza a INSTITUIÇÃO DE ENSINO a fornecer informações ao BRASIL MAIS BOLSAS referentes a pagamento das mensalidades, notas, faltas e comportamento disciplinar do ALUNO BENEFICIADO. </li>
                                        <li>
                                            O ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL declara estar ciente de que:
                                            <p className="mt-2 ml-4">a) A verificação pela INSTITUIÇÃO DE ENSINO ou pelo BRASIL MAIS BOLSAS de prestação inverídica de informação pelo ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL, no ato de inscrição no site do BRASIL MAIS BOLSAS, estará o BRASIL MAIS BOLSAS autorizado a rescindir automaticamente e de pleno direito o presente contrato, além de, a INSTITUIÇÃO DE ENSINO exigir do ALUNO BENEFICIADO o pagamento de todos os valores alcançados pelo benefício da bolsa de estudos enquanto vigente o mesmo;</p>
                                            <p className="mt-2 ml-4">b) A validade do benefício do Programa de BOLSAS DE ESTUDO dependerá da realização de matrícula do ALUNO BENEFICIADO na INSTITUIÇÃO DE ENSINO CONCEDENTE, na data constante no Edital de Processo Seletivo, e nos casos dos cursos de POS-GRADUAÇÃO, na data referida pela INSTITUIÇÃO DE ENSINO, sendo necessário observar a existência de vagas, assim como, a validade do benefício dependerá também da aprovação do ALUNO BENEFICIADO no processo de avaliação da INSTITUIÇÃO DE ENSINO, sempre que exigido;</p>
                                            <p className="mt-2 ml-4">c) Na hipótese de alteração do valor da mensalidade pela ESCOLA, divulgada no site do programa BRASIL MAIS BOLSAS, o benefício do Programa de BOLSAS do BRASIL MAIS BOLSAS descrita na alínea “a”, da Cláusula 1.2, incidirá sobre o valor da mensalidade bruta e atual da INSTITUIÇÃO DE ENSINO praticado no ato da matricula do ALUNO BENEFICIADO, observada a condição estabelecida na Cláusula 1.3;</p>
                                            <p className="mt-2 ml-4">d) O não pagamento pontual das mensalidades escolares, quer dizer, rigorosamente em dia, de qualquer parcela, simultânea ou não, assim como pagamento não integral de mensalidade, pelo O ALUNO ou RESPONSÁVEL LEGAL, implicará na inadimplência e obrigação de pagar o valor integral além das penalidades de mora, no percentual de 0.02% à título de juros moratórios e multa de 2% sobre o valor da/s mensalidade/s, podendo ainda a INSTITUIÇÃO DE ENSINO optar pela rescisão contratual deste Contrato, e, portanto, a não renovação do benefício para os próximos anos letivos, devido descumprimento contratual;</p>
                                            <p className="mt-2 ml-4">e) A infração de qualquer dispositivo deste Contrato implicará na sua rescisão sem prejuízo de outras penalidades aqui previstas, não sendo possível a sua renovação;</p>
                                            <p className="mt-2 ml-4">f) A não aprovação do ALUNO BENEFICIADO em processo seletivo e/ou análise documental da INSTITUIÇÃO DE ENSINO, ainda que tendo sido pago a taxa de Adesão ao BRASIL MAIS BOLSAS, ensejará a rescisão contratual automaticamente, estando o BRASIL MAIS BOLSAS e a INSTITUIÇÃO DE ENSINO isentos de qualquer responsabilidade para com o ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL;</p>
                                            <p className="mt-2 ml-4">g) Na hipótese descrita na alínea “f” da Cláusula 2.12, o valor da Taxa de Adesão efetivamente paga pelo ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL será reembolsado através do BRASIL MAIS BOLSAS;</p>
                                        </li>
                                        <li>O modo de pagamento de reembolso será realizado pelo mesmo meio eleito pelo ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL quando do pagamento da Taxa de Adesão;</li>
                                        <li>A ocorrência de qualquer das situações citadas nas alíneas da Cláusula 2.9, ou, na hipótese do ALUNO BENEFICIADO indicar interesse em rescindir o referido contrato de BOLSAS DE ESTUDOS, o referido aluno deverá comunicar o fato/motivo ao BRASIL MAIS BOLSAS, de modo que a INTERVENIENTE proceda com a suspensão de cobrança de Taxa Administrativa.</li>
                                    </ol>
                                </li>
                                <li className="mt-4">
                                    <p><h5>Cláusula Terceira — Do Prazo de Vigência Contratual</h5></p>
                                    <ol>
                                        <li>
                                            A vigência do presente Contrato se dá na data em que o ALUNO BENEFICIADO realiza o pagamento de Taxa de Adesão junto ao BRASIL MAIS BOLSAS e finda quando do vencimento da última mensalidade do semestre letivo para o Curso/Série a qual o RESPONSÁVEL ou ALUNO BENEFICIADO realizou adesão ao Programa.
                                            <ol>
                                                <li>O presente contrato será renovado automaticamente, desde que o ALUNO BENEFICIADO e/ou RESPONSÁVEL LEGAL cumpra/m com as obrigações do regimento da INSTITUIÇÃO DE ENSINO e do Regulamento do BRASIL MAIS BOLSAS até o fim da etapa de formação onde o aluno está matriculado, exceto na ocorrência de hipótese/s prevista/s neste instrumento que enseje a rescisão do mesmo ou diante da ausência de pagamento de Taxa Administrativa referente ao semestre a ser cursado.</li>
                                                <li>Quando o ALUNO BENEFICIADO cursar modalidade EAD, o término final será no sexto mês da contratação do benefício.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li className="mt-4">
                                    <p><h5>Cláusula Quarta — Renovações Periódicas</h5></p>
                                    <ol>
                                        <li>Exceto haja modificação de procedimento, a ser devidamente informado ao ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL, pelo BRASIL MAIS BOLSAS, a renovação à BOLSAS DE ESTUDO, tanto para INSTITUIÇÕES DE ENSINO de EDUCAÇÃO BÁSICA quanto para os cursos de: Graduação, Pós Graduação, Cursos Técnicos, Livres, Profissionalizantes, Idiomas, Matérias Isoladas, EJA — Educação para Jovens e Adultos, Preparatórios para Concursos e Pré-Vestibular/ENEM, sejam eles na modalidade Presencial ou EAD, ocorrerá a cada 06 (seis) meses a partir da data da contratação da Bolsa, sendo efetuada através do pagamento da Taxa Administrativa devida pelo ALUNO ou RESPONSÁVEL ao BRASIL MAIS BOLSAS, nos termos deste contrato.</li>
                                        <li>Na ocorrência de não pagamento pelo ALUNO BENEFICIADO ou RESPONÁVEL LEGAL da Taxa Administrativa em favor do BRASIL MAIS BOLSAS, será comunicada a INSTITUIÇÃO DE ENSINO a fim de que proceda com a imediata rescisão da vigência deste Contrato, perdendo o ALUNO BENEFICIADO os benefícios da BOLSA DE ESTUDOS.</li>
                                    </ol>
                                </li>
                                <li className="mt-4">
                                    <p><h5>Cláusula Quinta — Das Taxas de Adesão e Administrativa</h5></p>
                                    <ol>
                                        <li>
                                            Entre os requisitos para aquisição e manutenção do direito usufruto dos benefícios advindos de BOLSA DE ESTUDOS, o ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL, se vincula à obrigação de pagar ao BRASIL MAIS BOLSAS Taxa de Adesão e Taxa Administrativa, sob pena de resolução de pleno direito do presente Contrato, pela prestação de serviços administrativos de gestão de candidatos, seleção e aprovação em Programa de BOLSAS DE ESTUDO, nos moldes do determina este instrumento e o regulamento do Programa.
                                        <ol className="mt-3"><li><p>À título de Taxa de Adesão o valor corresponde a R$XXX,XX. (XXX X  XXXXX X) </p></li></ol>
                                        </li>
                                        <li>Será devido o pagamento de Taxa Administrativa a cada renovação periódica deste contrato, com valor correspondente à 30% (trinta por cento) do valor Mensalidade Atual da Instituição, corrigido pelo INPC até a data do seu efetivo pagamento.</li>
                                        <li>O pagamento da Taxa Administrativa deverá ser realizado a cada 06(seis) meses, cujo prazo inicia contagem a partir da sua data da adesão ao programa, seja na modalidade presencial ou para os cursos EAD.</li>
                                        <li>O atraso do pagamento das Taxas Administrativas, ou seja, em data posterior ao vencimento, ensejará a incidência de penalidades de mora, no percentual de 0,02% ao dia, à título de juros moratórios e multa de 2% sobre o valor da Taxa devida.</li>
                                        <li>A cobrança da taxa de renovação será realizada pelo BRASIL MAIS BOLSAS através de BOLETO BANCÁRIO.</li>
                                        <li>Poderá, ainda, o ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL optar para realizar o pagamento por meio de CARTÃO DE CRÉDITO, acessando o Portal do Aluno no site <u>www.brasilmaisbolsas.com.br</u></li>
                                        <li>
                                            A desistência de Curso ou Série pelo ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL dentro do prazo de 07 (sete) dias após a Adesão ou Renovação de BOLSA DE ESTUDO, via pagamento de Taxa de Adesão ou Taxa Administrativa, sem efetuação de matrícula/rematrícula, afere direito à restituição do valor integral pago à título de Taxa de Adesão ou Taxa Administrativa, o que será feito pelo BRASIL MAIS BOLSAS.
                                            <ol>
                                                <li>0 reembolso será efetuado pelo mesmo meio que foi utilizado pelo RESPONSÁVEL ou ALUNO BENEFICIADO para o pagamento da Taxa de Adesão ou Taxa administrativa;</li>
                                                <li>Deverá ser realizada solicitação de reembolso através do e-mail <u>financeiro@brasilmaisbolsas.com.br</u>, sendo indicada dados bancários e CPF do ALUNO BENEFICIADO ou do RESPONSÁVEL LEGAL cadastrado no site do BRASIL MAIS BOLSAS;</li>
                                                <li>Quando a solicitação for realizada após o prazo legal, indicado no caput da Cláusula 5.7, o reembolso será de 20% (vinte por cento) do valor pago à título de Taxa de Adesão ou Taxa Administrativa, e a sua efetuação dependerá da solicitação acompanhamento de dados bancários do ALUNO BENEFICIADO ou do RESPONSÁVEL LEGAL cadastrado no site do BRASIL MAIS BOLSAS e dados pessoais, como CPF;</li>
                                                <li>Para fins de verificação de autenticidade da solicitação será realizado contato direto com o Solicitante, através dos dados fornecidos neste contrato, à escolha do BRASIL MAIS BOLSAS, ou seja, via ligação telefônica, WhatsApp, notificação extrajudicial, ou outro meio que permita o registro de resposta;</li>
                                                <li>O prazo para reembolso é de 5 dias úteis, a partir da verificação de autenticidade do legítimo solicitante, qual seja do ALUNO BENEFICIADO ou do RESPONSÁVEL LEGAL cadastrado no site do BRASIL MAIS BOLSAS.</li>
                                            </ol>
                                        </li>
                                        <li>a desistência de Curso ou Série por ALUNO ou REPRESENTANTE LEGAL que haja contratado pessoalmente, não dá o direito de reembolso de Taxa de Adesão ou Taxa Administrativa, descrito na Cláusula 5.7</li>
                                        <li>A ausência de pagamento de qualquer das taxas referidas na Cláusula 5.1, ou o pagamento sem solicitação de reembolso de qualquer das Taxas pagas ao BRASIL MAIS BOLSAS por desistência, dentro das circunstâncias e prazo estipulado neste Contrato, implica a rescisão do presente contrato, e, portanto, na perda do benefício de BOLSA DE ESTUDOS, assim como, a cobrança por parte da INSTITUIÇÃO DE ENSINO do valor integral das mensalidades do Curso ou Série no qual o ALUNO BENEFICIADO esteja matriculado.</li>
                                    </ol>
                                </li>
                                <li className="mt-4">
                                    <p><h5>Cláusula Sexta — Disposições Gerais</h5></p>
                                    <ol>
                                        <li>O ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL declara conhecer e aceitar os termos deste contrato, principalmente os efeitos das Cláusulas 2.1.2.</li>
                                        <li>O ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL declara conhecer o regulamento do Programa de BOLSA de ESTUDOS do BRASIL MAIS BOLSAS e declara aceitar os termos deste contrato, registrado no 1º Cartório de Caruaru, Estado de Pernambuco sob número 1234567, isentando o BRASIL MAIS BOLSAS de qualquer responsabilidade caso a INSTITUIÇÃO DE ENSINO suspenda ainda que indevida e temporariamente, o benefício da bolsa que ora lhe é concedida.</li>
                                        <li>A existência de denúncia ou suspeita institucional do BRASIL MAIS BOLSAS quanto à veracidade das informações prestadas no site quando do ato de adesão ao Programa, o ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL autoriza, desde já, sem necessidade de prévia comunicação, que o BRASIL MAIS BOLSAS encaminhe ao seu endereço residencial Assistente Social e/ou outro profissional de sua confiança, para verificação in loco das informações prestadas e passíveis de conferência pessoalmente, sendo a negativa de tal visita motivo ensejador de rescisão imediata deste contrato, e consequentemente perda do benefício, exceto se apresentado fundamento razoável por escrito, dentro do prazo de 3 dias úteis após a data da visita. </li>
                                        <li>O aferimento de benefício de BOLSA DE ESTUDO pelo ALUNO BENEFICIADO veda a fruição de qualquer outro benefício da INSTITUIÇÃO DE ENSINO ou de qualquer ente governamental, tais como desconto para pagamento no vencimento, convênio empresa ou qualquer outro tipo de convenio ou medida que implique em redução do valor da mensalidade. </li>
                                        <li>Caso o ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL faça a opção por outro tipo de benefício, este contrato de bolsa de estudo será automaticamente rescindido de pleno direito.</li>
                                        <li>A verificação depois da contratação, de utilização pelo ALUNO BENEFICIADO de cumulação de benefícios que reduzam o valor da mensalidade do Curso ou Série do qual se beneficia em Programa de Estudo, em proveito próprio, salvo por liberalidade da INSTITUIÇÃO DE ENSINO, implicará na obrigação de reembolso à INSTITUIÇÃO DE ENSINO de todos os valores recebidos indevidamente, de forma indireta, pelo não pagamento advindo de ganho de benefícios extras não permitidos por este Contrato, uma vez que requisito para usufruir dos benefícios de BOLSA DE ESTUDO do Programa no qual fora aprovado, conforme dita as Cláusulas 6.4 e 6.5.</li>
                                        <li>A permissão pela ESCOLA, por mera liberalidade ou novo regramento institucional, do acúmulo de benefícios, ensejará na continuação de vigência deste Contrato de Bolsa de Estudos, sendo devidas pelo ALUNO BENEFICIADO ou RESPONSÁVEL LEGAL todas as obrigações decorrentes do mesmo.</li>
                                        <li>As partes ficam autorizadas a divulgar os termos deste contrato, inclusive nomes e identificação pessoal, tornando público os resultados do Programa de Bolsa de Estudos do BRASIL MAIS BOLSAS. </li>
                                        <li>Declaram o ALUNO BENEFICIADO e RESPONSÁVEL LEGAL, conhecimento de que a INSTITUIÇÃO DE ENSINO é a única responsável pelas informações prestadas ao BRASIL MAIS BOLSAS acerca dos valores de mensalidades, percentuais da bolsa e quantidade de vagas disponibilizadas para os Cursos ou Séries em Programa do BRASIL MAIS BOLSAS</li>
                                        <li>É isentado o BRASIL MAIS BOLSAS de qualquer responsabilidade se a INSTITUIÇÃO DE ENSINO prestar informações errônea ou proceder com cobrança indevida de mensalidades que deveriam estar incluídas no Programa do BRASIL MAIS BOLSAS.</li>
                                        <li>Declaram as partes CONTRATANTES aceitar todos os termos deste contrato, estando de acordo com todos as suas Cláusulas e condições.</li>
                                    </ol>
                                </li>
                                <li className="mt-4">
                                    <p><h5>Cláusula Sétima - Do Foro</h5></p>
                                    <ol>
                                        <li>
                                            É instituído o Foro da Comarca da sede do estabelecimento da INSTITUIÇÃO DE ENSINO para dirimir dúvidas decorrentes do presente instrumento de Contrato, renunciando as partes a qualquer outro, por mais especial ou privilegiado que seja.
                                        </li>
                                    </ol>
                                </li>
                            </ol>

                            <p className="mt-5">Estando justos e contratados, assinam o presente em 02 (duas) vias de igual teor e forma, na presença de duas testemunhas, de modo que produza os seus efeitos jurídicos e legais.</p>

                        </p>

                        <p>
                            <br />
                            <br />
                            <b>CARUARU/PE XX, DE XXXXXX, DE XXXXX</b>.
                            <br />

                        </p>
                        <p>
                            <br />
                            INSTITUIÇÃO DE ENSINO: <b>XXXXXXXX</b><br />
                            <br />
                            <br />
                            RESPONSÁVEL LEGAL: <b>XXXXXXXXXX</b>
                            <br />
                            <br />
                            <br />
                        </p>
                    </div>
            </div>
        )
    }
}
