import React, { Component } from 'react';

import MaskedInput from 'react-text-mask';
import CurrencyInput from 'react-currency-input';
import moment from 'moment';
import axios from 'axios';
import { Collapse, Modal } from 'react-bootstrap';

import { parseMoney, getShift } from '../../Util/Functions'
import Api from '../../Services/Api';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

import './index.css'
import { toast } from 'react-toastify';

function getPaymentType(type) {
    switch (type) {
        case 'credit_card':
            return 'Cartão de crédito';
        case 'boleto':
            return 'Boleto';
        default:
            return 'desconhecido'
    }
}

function getPaymentSituation(status) {
    switch (status) {
        case 'PAID':
            return <span className="text-green">PAGO</span>;
        case 'IN_ANALYSIS':
            return <span className="text-blue">PROCESSANDO</span>;
        case 'WAITING':
            return <span className="text-yellow">AGUARDANDO</span>;
        case 'CANCELLED':
            return <span className="text-danger">REJEITADO</span>;
        case 'REJECTED':
            return <span className="text-danger">REJEITADO</span>;
        default:
            break;
    }
}

const PaymentsModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pagamentos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-3 text-center font-weight-bold">Data</div>
                    <div className="col-3 text-center font-weight-bold">Tipo</div>
                    <div className="col-3 text-center font-weight-bold">Link</div>
                    <div className="col-3 text-center font-weight-bold">Situação</div>
                </div>
                {props.payments.map(payment => (
                    <div className="row border payment-item mx-1 my-3 p-3 no-gutters">
                        <div className="col-3 text-center">
                            <span className="mr-5">{moment(payment.updatedAt).format('DD/MM/YYYY')}</span>
                        </div>
                        <div className="col-3 text-center">
                            <span className="mr-2">{getPaymentType(payment.payment_type)}</span>
                        </div>
                        <div className="col-3 text-center">
                            <span className="ml-3">{payment.url_boleto ? <a href={payment.url_boleto} target="_blank" rel="noopener noreferrer" className="text-secondary">Acessar</a> : ' - '}</span>
                        </div>
                        <div className="col-3 text-center">
                            <span className="ml-5">{getPaymentSituation(payment.status)}</span>
                        </div>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    );
}

export default class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            orders: [],
            phone: "",
            whatsapp_phone: "",
            friend_phone: "",
            cpf: "",
            rg: "",
            rg_emissor: "",
            birthdate: "",
            familyIncome: null,
            familyIncomeFloat: 0,
            address: {
                street: "",
                city: "",
                state: "",
                number: "",
                postalCode: "",
                district: "",
                complement: ""
            },
            ccp: '',
            addressLoading: false,
            postalCodeError: false,
            has_dependent: false,
            minor_name: '',
            minor_birthdate: '',
            loginUsername: "",
            loginPassword: "",
            dependentCheck: false,
            showPaymentsModal: false,
            paymentsList: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    handleCepChange = async (value) => {
        this.setState({ addressLoading: true, postalCodeError: false });
        try {
            let response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
            this.setState({ addressLoading: false });
            if (response.data.erro === true) {
                this.setState({
                    postalCodeError: true,
                    address: {
                        street: "",
                        district: "",
                        city: "",
                        state: ""
                    }
                });
            } else {
                this.setState({
                    address: {
                        street: response.data.logradouro,
                        district: response.data.bairro,
                        city: response.data.localidade,
                        state: response.data.uf
                    },
                });
            }
        } catch (error) {
            console.log(error)
            this.setState({ postalCodeError: true, addressLoading: false })
            console.log(error.response);
        }
    }

    getData = async () => {
        try {
            let userRequest = await Api.get('/user/details/profile');
            console.log(userRequest)
            let user = userRequest.data.user;
            this.setState({
                name: user.name,
                phone: user.phone,
                whatsapp_phone: user.whatsapp_phone,
                friend_phone: user.friend_phone,
                email: user.email,
                cpf: user.cpf,
                rg: user.rg,
                rg_emissor: user.rg_emissor,
                birthdate: moment(user.birthdate).format('DD/MM/YYYY'),
                familyIncomeFloat: user.family_income,
                has_dependent: user.has_dependent,
                minor_name: user.minor_name,
                minor_birthdate: moment(user.minor_birthdate).format('DD/MM/YYYY'),
                address: {
                    street: user.address.street,
                    city: user.address.city,
                    state: user.address.state,
                    number: user.address.number,
                    postalCode: user.address.postal_code,
                    district: user.address.district,
                    complement: user.address.complement
                },
            });
            let scholarshipRequests = await Api.get('/order');
            console.log(scholarshipRequests)
            let orders = scholarshipRequests.data.orders.filter(order => order.payments.some(payment => ['PAID', 'WAITING'].includes(payment.status) && payment.UserId === order.UserId));
            
            this.setState({ orders })
        } catch (error) {
            console.log('erro')
            console.log(error.response)
        }
    }

    handleFamilyIncomeChange = (event, maskedvalue, floatvalue) => {
        this.setState({ familyIncome: maskedvalue, familyIncomeFloat: floatvalue });
    }

    togglePaymentsModal = async (orderId) => {
        if (!orderId) return this.setState({ showPaymentsModal: !this.state.showPaymentsModal })
        try {
            let payments = await Api.get('/order/payments?order_id=' + orderId);
            this.setState({ showPaymentsModal: !this.state.showPaymentsModal, paymentsList: payments.data.payments })
        } catch (error) {
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    handleUpdateSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(document.getElementById('register-form'));
        var userData = {};
        formData.forEach((value, key) => { userData[key] = value });
        userData.family_income = this.state.familyIncomeFloat;
        userData.birthdate = moment(userData.birthdate, 'DD/MM/YYYY').format('YYYY/MM/DD');
        userData.minor_birthdate = userData.minor_birthdate !== '' ? moment(userData.minor_birthdate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null;
        userData.has_dependent = userData.minor_name !== '';
        try {
            await Api.put('/user', userData);
            localStorage.setItem('user-name', userData.name);
            toast.success('Dados atualizados', { position: toast.POSITION.BOTTOM_RIGHT });
        } catch (error) {
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            console.log(error.response);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }

    }


    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="d-flex justify-content-center pt-3">
                    <div className="row page-content container-fluid">
                        <div className="col-12">
                            <h3>Minha conta</h3>
                        </div>
                        <div className="col-md-6">
                            <form className="row" onSubmit={this.handleUpdateSubmit} id="register-form">
                                {this.state.has_dependent ?
                                    <div className="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input has_dependent" type="checkbox" id="has_dependent" name="has_dependent" onChange={e => { this.setState({ dependentCheck: e.target.checked }) }} />
                                            <label class="form-check-label" for="has_dependent">
                                                Sou de maior - ( Marque está opção, caso o aluno e o responsável do cadastro sejam a mesma pessoa)
                                            </label>
                                        </div>
                                        <Collapse in={!this.state.dependentCheck}>
                                            <div>
                                                <div><h4>Dados do aluno</h4></div>
                                                <div className="form-group">
                                                    <label>Nome completo</label>
                                                    <input
                                                        value={this.state.minor_name}
                                                        onChange={e => { this.setState({ minor_name: e.target.value }) }}
                                                        className="form-control"
                                                        name="minor_name"
                                                        placeholder="Nome completo"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Data de nascimento</label>
                                                    <MaskedInput
                                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                        className="form-control"
                                                        name="minor_birthdate"
                                                        placeholder="DATA DE NASCIMENTO"
                                                        defaultValue={this.state.minor_birthdate ? this.state.minor_birthdate : ""}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                    :
                                    null}
                                <div className="col-12"><h4>Dados do responsavel</h4></div>
                                <div className="col-12 form-group">
                                    <label>Nome completo</label>
                                    <input
                                        value={this.state.name}
                                        onChange={e => { this.setState({ name: e.target.value }) }}
                                        className="form-control"
                                        name="name"
                                        placeholder="Nome completo"
                                        required
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <label>Telefone (Celular)</label>
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control"
                                        name="phone"
                                        placeholder="Telefone"
                                        defaultValue={this.state.phone ? this.state.phone : ""}
                                        required
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <label>Telefone (Whatsapp)</label>
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control"
                                        name="whatsapp_phone"
                                        placeholder="Telefone (Whatsapp)"
                                        defaultValue={this.state.whatsapp_phone ? this.state.whatsapp_phone : ""}
                                        required
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <label>Telefone (Amigo ou parente)</label>
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control"
                                        name="friend_phone"
                                        placeholder="Telefone"
                                        defaultValue={this.state.friend_phone ? this.state.friend_phone : ""}
                                        required
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <label>CPF</label>
                                    <MaskedInput
                                        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
                                        className="form-control"
                                        name="cpf"
                                        placeholder="CPF"
                                        defaultValue={this.state.cpf ? this.state.cpf : ""}
                                        required
                                    />
                                </div>
                                <div className="col-6 col-md-7 form-group">
                                    <label>RG</label>
                                    <input
                                        value={this.state.rg}
                                        onChange={e => { this.setState({ rg: e.target.value }) }}
                                        className="form-control"
                                        name="rg"
                                        placeholder="RG"
                                        required
                                    />
                                </div>
                                <div className="col-6 col-md-5 form-group">
                                    <label>Emissor</label>
                                    <input
                                        value={this.state.rg_emissor}
                                        onChange={e => { this.setState({ rg_emissor: e.target.value }) }}
                                        className="form-control"
                                        name="rg_emissor"
                                        placeholder="órg. Emissor"
                                        required
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <label>Data de nascimento</label>
                                    <MaskedInput
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control"
                                        name="birthdate"
                                        placeholder="DATA DE NASCIMENTO"
                                        defaultValue={this.state.birthdate ? this.state.birthdate : ""}
                                        required
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <label>Renda familiar mensal</label>
                                    <CurrencyInput
                                        value={this.state.familyIncomeFloat}
                                        onChangeEvent={this.handleFamilyIncomeChange}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        prefix="R$"
                                        className="form-control"
                                        placeholder="Renda familar mensal"
                                        required
                                    />
                                </div>
                                <div className="col-11 form-group">
                                    <label>CEP</label>
                                    <input
                                        className="form-control"
                                        name="postal_code"
                                        value={this.state.address.postalCode}
                                        onChange={e => { this.setState({ address: { ...this.state.address, postalCode: e.target.value } }) }}
                                        onBlur={e => { this.handleCepChange(e.target.value) }}
                                        placeholder="CEP"
                                        required
                                    />
                                    {this.state.postalCodeError
                                        ?
                                        (
                                            <div className="cep-error-message text-danger">
                                                Erro ao buscar endereço
                                    </div>
                                        )
                                        :
                                        null
                                    }
                                </div>
                                {this.state.addressLoading
                                    ?
                                    (
                                        <div className="col-1 form-group d-flex align-items-center justify-content-center">
                                            <i className="fas fa-circle-notch fa-spin"></i>
                                        </div>
                                    )
                                    :
                                    null
                                }
                                <div className="col-md-7 form-group">
                                    <label>Rua</label>
                                    <input
                                        value={this.state.address.street}
                                        onChange={e => { this.setState({ address: { ...this.state.address, street: e.target.value } }) }}
                                        className="form-control"
                                        name="street"
                                        placeholder="Rua"
                                        required
                                    />
                                </div>
                                <div className="col-md-5 form-group">
                                    <label>Número</label>
                                    <input
                                        value={this.state.address.number}
                                        onChange={e => { this.setState({ address: { ...this.state.address, number: e.target.value } }) }}
                                        className="form-control"
                                        name="number"
                                        placeholder="Número"
                                        required
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Complemento</label>
                                    <input
                                        value={this.state.address.complement}
                                        onChange={e => { this.setState({ address: { ...this.state.address, complement: e.target.value } }) }}
                                        className="form-control"
                                        name="complement"
                                        placeholder="Complemento"
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Cidade</label>
                                    <input
                                        value={this.state.address.city}
                                        onChange={e => { this.setState({ address: { ...this.state.address, city: e.target.value } }) }}
                                        className="form-control"
                                        name="city"
                                        placeholder="Cidade"
                                        required
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Bairro</label>
                                    <input
                                        value={this.state.address.district}
                                        onChange={e => { this.setState({ address: { ...this.state.address, district: e.target.value } }) }}
                                        className="form-control"
                                        name="district"
                                        placeholder="Bairro"
                                        required
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Estado</label>
                                    <select
                                        className="form-control"
                                        placeholder="Estado"
                                        defaultValue={this.state.address.state}
                                        value={this.state.address.state}
                                        onChange={e => { this.setState({ address: { ...this.state.address, state: e.target.value } }) }}
                                        name="state"
                                        required
                                    >
                                        <option disabled value="">Selecione o estado</option>
                                        <option value="AC">Acre</option>
                                        <option value="AL">Alagoas</option>
                                        <option value="AP">Amapá</option>
                                        <option value="AM">Amazonas</option>
                                        <option value="BA">Bahia</option>
                                        <option value="CE">Ceará</option>
                                        <option value="DF">Distrito Federal</option>
                                        <option value="ES">Espírito Santo</option>
                                        <option value="GO">Goiás</option>
                                        <option value="MA">Maranhão</option>
                                        <option value="MT">Mato Grosso</option>
                                        <option value="MS">Mato Grosso do Sul</option>
                                        <option value="MG">Minas Gerais</option>
                                        <option value="PA">Pará</option>
                                        <option value="PB">Paraíba</option>
                                        <option value="PR">Paraná</option>
                                        <option value="PE">Pernambuco</option>
                                        <option value="PI">Piauí</option>
                                        <option value="RJ">Rio de Janeiro</option>
                                        <option value="RN">Rio Grande do Norte</option>
                                        <option value="RS">Rio Grande do Sul</option>
                                        <option value="RO">Rondônia</option>
                                        <option value="RR">Roraima</option>
                                        <option value="SC">Santa Catarina</option>
                                        <option value="SP">São Paulo</option>
                                        <option value="SE">Sergipe</option>
                                        <option value="TO">Tocantins</option>
                                    </select>
                                </div>
                                <div className="col-12 form-group">
                                    <label>Email</label>
                                    <input
                                        value={this.state.email}
                                        onChange={e => { this.setState({ email: e.target.value }) }}
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        disabled
                                        required
                                    />
                                </div>
                                <div className="col-12 d-flex justify-content-center justify-content-md-start mb-3">
                                    <a href="/contrato_default" target="_blank" className="d-inline mx-1">Contrato</a>|
                                <a href="/termos-uso" target="_blank" className="d-inline mx-1">Termos de uso</a>
                                </div>
                                <div className="col-md-5 mb-3">
                                    <button type="submit" className=" btn btn-secondary btn-block text-white">ENVIAR</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="col-12 mb-3"><h4>Minhas matrículas</h4></div>
                            <div className="col-12 my-scholarships-container p-0 mb-3">
                                {this.state.orders.map(order => (
                                    <div className="row border scholarship-item mt-4 mx-1 mx-md-0" key={order.id}>
                                        <div className="col-md-12 mt-3 mt-md-0">
                                            <div className="row d-flex align-items-center h-100">
                                                <div className="col-md-6">
                                                    {console.log(order)}
                                                    <h4 className="d-block">{order.scholarship.course.name}</h4>
                                                    <span className="d-block">Instituição: <b>{order.scholarship.institution.name}</b></span>
                                                    <span className="d-block">Turno: {getShift(order.scholarship.shift)}</span>
                                                    <span className="d-block">Modalidade: {order.scholarship.type.toUpperCase()}</span>
                                                    <span className="d-block">Local: {order.scholarship.institution.city.toUpperCase()} - {order.scholarship.institution.district.toUpperCase()}</span>
                                                    {order.scholarship.signedContracts && order.scholarship.signedContracts.length > 0 && (<span className="d-block">Contrato Assinado: Sim</span>)}
                                                </div>
                                                <div className="col-md-5 pl-md-5 mt-3 mt-md-0">
                                                    <div className="row h-50 d-flex align-items-end">
                                                        <div className="col-6">
                                                            <strike className="d-block">{parseMoney(order.scholarship.full_price)}</strike>
                                                            <h5 className="font-weight-bold">{parseMoney(order.scholarship.final_price)}</h5>
                                                        </div>
                                                        <div className="col-6 text-right">
                                                            <span className="d-block">Desconto:</span>
                                                            <h5 className="font-weight-bold">{order.scholarship.discount}%</h5>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 text-center mb-3 mb-md-0">
                                                            <button onClick={() => { this.togglePaymentsModal(order.id) }} className="btn btn-secondary btn-block btn-buy-scholarship text-white d-flex align-items-center justify-content-center mb-4 mb-md-0">
                                                                <span className="ml-2 mt-1">Pagamentos</span>
                                                            </button>
                                                            {!order.payments.find(x => x.status === 'PAID')
                                                                ?
                                                                <div></div>
                                                                :
                                                                <div className="mt-1">
                                                                    <a href={"/contrato?bolsa=" + order.scholarship.id} className="text-secondary">Contrato </a> |
                                                                    <a href={"/cupom?bolsa=" + order.scholarship.id} className="text-secondary"> Cupom</a>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <PaymentsModal
                        show={this.state.showPaymentsModal}
                        onHide={this.togglePaymentsModal}
                        payments={this.state.paymentsList}
                    />
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
