import axios from 'axios';
import config from '../Config'

let headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
}

if (localStorage.getItem('user-token')) {
    headers.Authorization = "bearer " + localStorage.getItem('user-token');
}

const Api = axios.create({
    baseURL: config.API_BASE_URL,
    headers
});

Api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.data && error.response.data.message === 'token-expired') {
        localStorage.clear();
        return window.location.reload();
    }
    return Promise.reject(error);
});

export default Api;