import React, { Component, lazy, Suspense } from 'react';

import { Redirect } from 'react-router-dom';
import qs from 'query-string';
import { withCookies } from 'react-cookie';

import Api from '../../Services/Api';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';

const LazyFeatures = lazy(() => import('./features'));
const LazyScholarships = lazy(() => import('./scholarships'));
const LazyFilter = lazy(() => import('./filter'));

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            scholarships: [],
            selectedCategory: null,
            selectedScholarshipType: 'course',
            selectedCategoryName: '',
            categoryBgIndex: 1,
            searchQuery: '',
            selectedCity: '',
            dataLoaded: false
        }

        this.filterRef = React.createRef()
    }

    componentDidMount() {
        this.getData();
        let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        if (search.parceiro) {
            const { cookies } = this.props;
            cookies.set('parceiro', search, { path: '/' });
        }
    }

    getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    handleFilterTypeChange = (type) => {
        this.setState({ selectedScholarshipType: type })
    }

    getData = async () => {
        let scholarshipsRequest = await Api.get('/scholarship/list/index', { params: { offset: 0 } });

        this.setState({
            scholarships: scholarshipsRequest.data.scholarships,
            dataLoaded: true,
        })
    }

    getModelImage = () => {
        return `/img/${this.state.categoryBgIndex}.webp`;
    }

    getBgImage = () => {
        return `url(/img/school_bg.webp)`;
    }

    render() {
        if (this.state.redirect === true) 
            return <Redirect to={"/bolsas?" + this.state.searchQuery} />

        return (
            <div className="container-fluid">
                <Header />

                {/* Banner (Principal) */}
                <div className="row home-filter-root-container">
                    <div
                        className="background-container col-12"
                        style={{
                            backgroundImage: this.getBgImage(),
                            backgroundColor: 'white',
                            backgroundSize: 'cover'
                        }}
                    >
                        <div className="filter-model-col">
                            <img className="img-fluid img-model" width="400" height="600" alt="bg-img" src={this.getModelImage()}></img>
                        </div>
                        <div className="filter-text-container">
                            <div className="txt">
                                <span className="linha linha-1">ESTUDE COM</span>
                                <span className="linha linha-2">BOLSAS DE ATÉ:</span>
                                <span className="linha linha-3"><span className="arrows"> &gt;&gt;&gt;</span><span className="maior">70%</span></span>
                                <span className="linha linha-4">DE DESCONTO</span>
                            </div>
                        </div>
                    </div>
                    
                    <Suspense fallback={<div className="col-12 filter-container">Carregando filtros...</div>}>
                        <LazyFilter />
                    </Suspense>
                </div>
                
                <Suspense fallback={<div className="col-12 features-container">Carregando recursos...</div>}>
                    <LazyFeatures />
                </Suspense>
                
                {this.state.dataLoaded ? (
                    <Suspense fallback={<div className="col-12 slider-container">Carregando bolsas...</div>}>
                        <LazyScholarships scholarships={this.state.scholarships} />
                    </Suspense>
                ) : (
                    <div className="col-12 slider-container">Carregando bolsas...</div>
                )}

                <Footer />
            </div>
        )
    }
}

export default withCookies(Home);