import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import MaskedInput from 'react-text-mask';
import CurrencyInput from 'react-currency-input';
import { toast } from 'react-toastify';
import moment from 'moment';
import qs from 'query-string';
import axios from 'axios';
import Loader from 'react-loading-components';
import { withCookies } from 'react-cookie';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

import Api from '../../Services/Api';

import { isAuthenticated } from '../../Services/Authentication';
import { CPFValidate } from '../../Util/Functions';

import './index.css'

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            phone: "",
            cpf: "",
            rg: "",
            birthdate: "",
            familyIncome: null,
            familyIncomeDouble: 0,
            address: {
                street: "",
                city: "",
                state: "",
                number: "",
                postalCode: "",
                district: "",
            },
            ccp: '',
            addressLoading: false,
            postalCodeError: false,
            loginUsername: "",
            loginPassword: "",
            selfResponsible: false,
            loading: false
        }
    }

    componentDidMount() {
        const { cookies } = this.props;
        let parceiro = cookies.get('parceiro');
        console.log(parceiro);
    }

    handleCepChange = async (value) => {
        this.setState({ addressLoading: true, postalCodeError: false });
        try {
            let response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
            this.setState({ addressLoading: false });
            if (response.data.erro === true) {
                this.setState({
                    postalCodeError: true,
                    address: {
                        street: "",
                        district: "",
                        city: "",
                        state: ""
                    }
                });
            } else {
                this.setState({
                    address: {
                        street: response.data.logradouro,
                        district: response.data.bairro,
                        city: response.data.localidade,
                        state: response.data.uf
                    },
                });
            }
        } catch (error) {
            console.log(error)
            this.setState({ postalCodeError: true, addressLoading: false })
            console.log(error.response);
        }
    }

    handleFamilyIncomeChange = (event, maskedvalue, floatvalue) => {
        this.setState({ familyIncome: maskedvalue, familyIncomeFloat: floatvalue });
    }

    handleRegisterSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formData = new FormData(document.getElementById('register-form'));
        var userData = {};
        formData.forEach((value, key) => { userData[key] = value });
        userData.family_income = this.state.familyIncomeFloat;
        userData.has_dependent = userData.minor_name !== "";
        userData.minor_birthdate = userData.minor_birthdate !== "" ? moment(userData.minor_birthdate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null;
        userData.birthdate = moment(userData.birthdate, 'DD/MM/YYYY').format('YYYY/MM/DD');
        let query = qs.parse(this.props.location.search);
        userData.register_scholarship = query.bolsa;
        userData.ccp = userData.source !== 'Outros' ? userData.source : userData.source_text;
        if (!CPFValidate(userData.cpf.replace(/\D+/g, ''))) {
            this.setState({ loading: false });
            return toast.error('CPF inválido.', { position: toast.POSITION.BOTTOM_RIGHT });
        }

        try {
            const { cookies } = this.props;
            let parceiro = cookies.get('parceiro');
            if (parceiro && parceiro.parceiro) userData.partner_code = parceiro.parceiro;
            await Api.post('/user/register', userData);
            let loginResponse = await Api.post('/auth', { username: userData.email, password: userData.cpf.replace(/\D+/g, '') });
            localStorage.setItem('user-name', loginResponse.data.user);
            localStorage.setItem('user-token', loginResponse.data.token);
            localStorage.setItem('logged', true);
            window.location.reload();
        } catch (error) {
            this.setState({ loading: false });
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            console.log(error.response);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    handleLogin = async (e) => {
        e.preventDefault();
        this.setState({ loginError: false, loading: true })
        try {
            let response = await Api.post('/auth', { username: this.state.loginUsername, password: this.state.loginPassword });
            await localStorage.setItem('user-name', response.data.user);
            await localStorage.setItem('user-token', response.data.token);
            await localStorage.setItem('logged', true);
            window.location.reload()
        } catch (error) {
            this.setState({ loginError: true, loading: false })
            console.log(error.response)
        }
    }


    render() {
        if (isAuthenticated()) return <Redirect to={"/matricula" + this.props.location.search} />
        return (
            <div className="container-fluid">
                <Header />
                <div className="row d-flex main-content mx-lg-5 px-lg-5 justify-content-center">
                    <div className="col-md-6 col-xxl-4 border-right px-lg-5">
                        <form className="row p-3 d-flex flex-column login-form" onSubmit={this.handleLogin}>
                            <h3 className="d-block mb-3">Entre com suas credenciais</h3>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="text"
                                    name="username"
                                    className="form-control"
                                    value={this.state.loginUsername}
                                    onChange={(e) => { this.setState({ loginUsername: e.target.value }) }}
                                />
                            </div>
                            <div className="form-group">
                                <label>CPF</label>
                                <input
                                    name="password"
                                    className="form-control"
                                    value={this.state.loginPassword}
                                    onChange={(e) => { this.setState({ loginPassword: e.target.value }) }}
                                />
                            </div>
                            {this.state.loginError
                                ?
                                <div className="form-group">
                                    <span className="text-danger">Credenciais invalidas</span>
                                </div>
                                :
                                null
                            }
                            <div className="row text-center d-flex align-items-center">
                                <div className="col-md-5">
                                    {this.state.loading ?
                                        <Loader type='tail_spin' width={40} height={40} fill='#14496b' />
                                        :
                                        <button type="submit" className="btn btn-secondary btn-block text-white">ENTRAR</button>
                                    }
                                </div>
                                <div className="col-md-7 mt-3 mt-md-0">
                                    <a href={"/recuperar_senha?user=" + this.state.loginUsername} className="forget-password">Esqueci minha senha</a>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-xxl-4">
                        <form className="row p-md-3" onSubmit={this.handleRegisterSubmit} id="register-form">
                            <div className="col-12"><h3>Ou cadastre-se agora</h3></div>
                            <div className="col-12">
                                <div>
                                    <div><h4>Dados do aluno</h4></div>
                                    <div className="form-group">
                                        <input
                                            value={this.state.minor_name}
                                            onChange={e => { this.setState({ minor_name: e.target.value }) }}
                                            className="form-control"
                                            name="minor_name"
                                            placeholder="Nome completo do aluno"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <MaskedInput
                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                            className="form-control"
                                            name="minor_birthdate"
                                            placeholder="DATA DE NASCIMENTO"
                                            defaultValue={this.state.minor_birthdate ? this.state.minor_birthdate : ""}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12"><h4>Dados do responsável</h4></div>
                            <div className="col-12 form-group">
                                <input className="form-control" name="name" placeholder="Nome completo" required />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    name="phone"
                                    placeholder="Telefone"
                                    defaultValue={this.state.phone ? this.state.phone : ""}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    name="whatsapp_phone"
                                    placeholder="Telefone (Whatsapp)"
                                    defaultValue={this.state.whatsapp_phone ? this.state.whatsapp_phone : ""}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    name="friend_phone"
                                    placeholder="Telefone para recado (amigo ou parente)"
                                    defaultValue={this.state.friend_phone ? this.state.friend_phone : ""}
                                    required
                                />
                            </div>
                            <div className="col-6 col-md-7 form-group">
                                <input className="form-control" name="rg" placeholder="RG" required />
                            </div>
                            <div className="col-6 col-md-5 form-group">
                                <input className="form-control" name="rg_emissor" placeholder="órg. Emissor" required />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    name="birthdate"
                                    placeholder="DATA DE NASCIMENTO"
                                    defaultValue={this.state.birthdate ? this.state.birthdate : ""}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group">
                                <CurrencyInput
                                    value={this.state.familyIncome}
                                    onChangeEvent={this.handleFamilyIncomeChange}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    className="form-control"
                                    placeholder="Renda familar mensal"
                                    required
                                />
                            </div>
                            <div className="col-11 form-group">
                                <input className="form-control" name="postal_code" onBlur={e => { this.handleCepChange(e.target.value) }} placeholder="CEP" required />
                                {this.state.postalCodeError
                                    ?
                                    (
                                        <div className="cep-error-message text-danger">
                                            Erro ao buscar endereço
                                    </div>
                                    )
                                    :
                                    null
                                }
                            </div>
                            {this.state.addressLoading
                                ?
                                (
                                    <div className="col-1 form-group d-flex align-items-center justify-content-center">
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </div>
                                )
                                :
                                null
                            }
                            <div className="col-md-7 form-group">
                                <input
                                    value={this.state.address.street}
                                    onChange={e => { this.setState({ address: { ...this.state.address, street: e.target.value } }) }}
                                    className="form-control"
                                    name="street"
                                    placeholder="Rua"
                                    required
                                />
                            </div>
                            <div className="col-md-5 form-group">
                                <input
                                    value={this.state.address.number}
                                    onChange={e => { this.setState({ address: { ...this.state.address, number: e.target.value } }) }}
                                    className="form-control"
                                    name="number"
                                    placeholder="Número"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <input
                                    className="form-control"
                                    name="complement"
                                    placeholder="Complemento"
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <input
                                    value={this.state.address.city}
                                    onChange={e => { this.setState({ address: { ...this.state.address, city: e.target.value } }) }}
                                    className="form-control"
                                    name="city"
                                    placeholder="Cidade"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <input
                                    value={this.state.address.district}
                                    onChange={e => { this.setState({ address: { ...this.state.address, district: e.target.value } }) }}
                                    className="form-control"
                                    name="district"
                                    placeholder="Bairro"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <select
                                    className="form-control"
                                    placeholder="Estado"
                                    defaultValue={this.state.address.state}
                                    value={this.state.address.state}
                                    onChange={e => { this.setState({ address: { ...this.state.address, state: e.target.value } }) }}
                                    name="state"
                                    required
                                >
                                    <option disabled value="">Selecione o estado</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                            <div className="col-12"><h4>Dados de acesso</h4></div>
                            <div className="col-12 form-group">
                                <input className="form-control" type="email" name="email" placeholder="Email" required />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
                                    className="form-control"
                                    name="cpf"
                                    placeholder="CPF"
                                    defaultValue={this.state.cpf ? this.state.cpf : ""}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group">
                                <select className="form-control" name="source" defaultValue="" required>
                                    <option disabled value="">Como você conhece o Brasil Mais Bolsas?</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Google">Google</option>
                                    <option value="Carro de som">Carro de som</option>
                                    <option value="Cartazes">Cartazes</option>
                                    <option value="Faixas">Faixas</option>
                                    <option value="TV">TV</option>
                                    <option value="Rádio">Rádio</option>
                                    <option value="Outdoor">Outdoor</option>
                                    <option value="Busdoor">Busdoor</option>
                                    <option value="Indicação de amigos">Indicação de amigos</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </div>
                            <div className="form-group col-12">
                                <input type="text" className="form-control" name="source_text" placeholder="Outros (opcional)" />
                            </div>
                            <div className="col-12 d-flex justify-content-center justify-content-md-start mb-3">
                                <a href="/contrato_default" target="_blank" className="d-inline mx-1">Contrato</a>|
                                <a href="/termos-uso" target="_blank" className="d-inline mx-1">Termos de uso</a>
                            </div>
                            <div className="col-md-5 mb-3">
                                {this.state.loading ?
                                    <Loader type='tail_spin' width={40} height={40} fill='#14496b' />
                                    :
                                    <button type="submit" className=" btn btn-secondary btn-block text-white">ENVIAR</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

export default withCookies(Login);