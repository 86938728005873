import React, { Component } from 'react';
import qs from 'query-string';
import Loader from 'react-loading-components';
import { toast } from 'react-toastify';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Api from '../../Services/Api';

import './index.css';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            loading: false,
            error: false,
            sent: false,
            password: '',
            repassword: ''
        }
    }

    componentDidMount() {
        let query = qs.parse(this.props.location.search);
        if (query.token) {
            this.setState({ token: query.token })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, error: false })
        if (this.state.password !== this.state.repassword) {
            this.setState({ loading: false });
            return toast.error('Suas senhas não conferem', { position: toast.POSITION.BOTTOM_RIGHT })
        };
        try {
            let passwordRequest = await Api.post('/auth/password_reset', {
                token: this.state.token,
                password: this.state.password,
                repassword: this.state.repassword
            });
            let passwordResponse = passwordRequest.data;
            if (passwordResponse.ok && passwordResponse.ok === true) {
                this.setState({ sent: true, loading: false, error: false })
            }
        } catch (error) {
            this.setState({ loading: false, error: true })
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }

    }

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="row main-container">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        {this.state.sent ?
                            <div>
                                <h3>Pronto</h3>
                                <span>Sua senha foi redefinida, faça login usando suas novas credenciais.</span>
                            </div>
                            :
                            <form className="row p-3 d-flex flex-column login-form" onSubmit={this.handleSubmit}>
                                <h3 className="d-block mb-3">Recuperar senha</h3>
                                <div className="form-group">
                                    <label>Nova senha</label>
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        value={this.state.password}
                                        onChange={(e) => { this.setState({ password: e.target.value }) }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Digite novamente</label>
                                    <input
                                        type="password"
                                        name="repassword"
                                        className="form-control"
                                        value={this.state.repassword}
                                        onChange={(e) => { this.setState({ repassword: e.target.value }) }}
                                    />
                                </div>
                                <div className="row text-center d-flex align-items-center">
                                    <div className="col-md-5">
                                        {this.state.loading ?
                                            <Loader type='tail_spin' width={40} height={40} fill='#14496b' />
                                            :
                                            <button type="submit" className="btn btn-secondary btn-block text-white">ENVIAR</button>
                                        }
                                    </div>
                                </div>
                            </form>}
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
