import React, { Component } from 'react';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Api from '../../Services/Api';

import './index.css'
import { toast } from 'react-toastify';

export default class Partners extends Component {
    constructor(props) {
        super(props)

        this.state = {
            users: [],
            partner: null,
            loading: true,
            authenticated: false
        }
    }


    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var formData = new FormData(document.getElementById('partner-login-form'));
            var creditCardData = {};
            formData.forEach((value, key) => { creditCardData[key] = value });
            let request = await Api.post('/partner/login', creditCardData);
            let partner = request.data.partner;
            this.setState({ authenticated: true, partner });
        } catch (error) {
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            console.log(error.response);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="d-flex flex-column justify-content-center align-items-center page-content p-1 p-sm-4">
                    {
                        this.state.authenticated
                            ?
                            <div className="text-center">
                                <h4>Bem vindo, {this.state.partner.name}.</h4>
                                <div>Até o momento <h3 className="d-inline">{this.state.partner.UsersCount}</h3> usuários se matricularam através do seu link!</div>
                                <div className="mt-4">
                                    <h5>Usuários:</h5>
                                    {this.state.partner.users.map(user => {
                                        console.log(user);
                                        return <span className="d-block">{user.name}</span>
                                    })}
                                </div>
                            </div>
                            :
                            <div className="col-12 col-md-4">
                                <form id="partner-login-form" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="code"
                                            className="form-control"
                                            placeholder="Seu código de parceiro"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Sua senha"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn-primary btn-lg">
                                            <span>Entrar</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
