import React, { Component } from 'react';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

import './index.css'

export default class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="container-fluid about-content p-1 p-md-4 d-flex align-items-center justify-content-center text-center">
                    <span>
                        O Brasil Mais Bolsas é um programa de captação de alunos que através de bolsa de estudos permite que, estudantes impossibilitados de pagar uma mensalidade integral, tenham acesso às melhores instituições de ensino particulares por meio de bolsa de estudos parciais a partir de 30% de desconto.
    Através das nossas das nossas campanhas de mídia e marketing digital, realizamos a captação de novos alunos e leads para as Instituições de Ensino que são parceiras do programa.
                    </span>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
