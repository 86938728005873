import React, { Component } from 'react';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

import './index.css'

export default class Home extends Component {

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <div className="row d-flex justify-content-center align-items-center page-content">
                    <h2>Página não encontrada</h2>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
