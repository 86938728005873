import React, { Component } from 'react';

import { Navbar, Nav, Collapse, Modal } from 'react-bootstrap';
import Api from '../../Services/Api';
import { isAuthenticated } from '../../Services/Authentication';
import moment from 'moment';
import $ from 'jquery';

import './index.css'
import '../../Libs/timeto/timeto-min'
import '../../Libs/timeto/timeTo.css'

export default class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categories: [],
            categoriesMenuOpen: false,
            showLoginModal: false,
            loginUsername: "",
            loginPassword: ""
        }
    }

    componentDidMount() {
        this.getData();
        $('#countdown').timeTo({
            timeTo: new Date(moment().add(2, 'days').endOf('day')),
            displayDays: 2,
            displayCaptions: true,
            lang: 'pt',
            fontSize: 32,
            captionSize: 14
        });

    }

    getData = async () => {
        let categoriesRequest = await Api.get('/courseCategory');
        this.setState({
            categories: categoriesRequest.data.courseCategories
        })
    }

    toggleCategoriesMenu = () => {
        this.setState({ categoriesMenuOpen: !this.state.categoriesMenuOpen });
    }

    handleClose = () => {
        console.log('close')
    }

    toggleLoginModal = () => {
        this.setState({ showLoginModal: !this.state.showLoginModal });
    }

    handleLogin = async (e) => {
        e.preventDefault();
        this.setState({ loginError: false })
        try {
            console.log("aa " + this.props.location)
            let response = await Api.post('/auth', { username: this.state.loginUsername, password: this.state.loginPassword });
            localStorage.setItem('user-name', response.data.user);
            localStorage.setItem('user-token', response.data.token);
            localStorage.setItem('logged', true);
            this.toggleLoginModal();
            this.forceUpdate();
        } catch (error) {
            this.setState({ loginError: true })
            console.log(error.response)
        }
    }

    timerRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span></span>;
        } else {
            // Render a countdown
            return <div className="ml-3"><span className="text-white text-small">Acaba em:</span><h4 className="text-white m-0 p-0">{hours}:{minutes}:{seconds}</h4></div>;
        }
    };

    render() {
        return (
            <div className="top-menu">
                {/* First Header */}
                <div className="row">
                    {/* 
                    <div className="col-12 promotion-header-container">
                        <div className="row h-100">
                            <div className="col-md-6 d-none d-md-flex align-items-center justify-content-center justify-content-md-end">
                                <img src={require('../../Images/megafone.png')} className="promotion-icon d-none d-md-inline" alt="megafone" />
                                <h5 className="ml-0 ml-md-4 text-white font-weight-bold text-center">As promoções deste site são temporárias. Aproveite!</h5>
                            </div>
                            <div className="col-md-4 d-flex flex-column align-items-center justify-content-center countdown-container">
                                <h5 className="text-center text-white">Promoção pós carnaval. Finaliza em:</h5>
                                <div><span id="countdown"></span></div>
                            </div>
                        </div>
                    </div>
                    */}
                </div>
                {/* Second Header (Nav bar) */}
                <div className="row bg-primary align-items-center">
                    <div className="col-6 col-md-2 d-flex justify-content-start p-2">
                        <a href="/" className="ml-sm-2">
                            <img src={require('../../Images/logo.webp').default} width="200" height="91" alt="logo" ></img>
                        </a>
                    </div>
                    <div className="col-6 col-md-7">
                        <div className="d-flex align-items-center justify-content-center">
                            <Navbar expand="lg" variant="dark">
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="mr-auto text-center">
                                        <Nav.Link href="/como_funciona"><span>COMO FUNCIONA</span></Nav.Link>
                                        <Nav.Link href="/faq"><span>FAQ</span></Nav.Link>
                                        <Nav.Link href="/fale_conosco"><span>FALE CONOSCO</span></Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 d-flex align-items-center justify-content-end p-2">
                        <div className="d-flex align-items-center justify-content-end wpp-color">
                            <i className="fab fa-whatsapp fa-2x"></i>
                            <a href="https://wa.me/558197112781" target="_blank" rel="noopener noreferrer" className="ml-2 font-weight-bold wpp-color wpp-text" style={{ color: 'white' }} >Envie uma mensagem</a>
                        </div>
                        
                        {isAuthenticated() ? (
                            <div className="ml-md-5 pl-md-4">
                                <span style={{ color: 'white' }}>Olá {localStorage.getItem('user-name')} | </span>
                                <a href="/minha_conta" className="text-white mr-2">Painel</a>
                                <a href="/indicacoes" className="text-white mr-2">Indicações</a>
                                <a href="/logout" className="text-white">Sair</a>
                            </div>
                        ) : (
                            <div className="login-btn">
                                <button style={{ color: 'white' }} className="btn btn-link font-weight-bold btn-login" onClick={this.toggleLoginModal}>
                                    <i className="fas fa-user-alt mr-2"></i><span>PORTAL DO ALUNO</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row sub-menu">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-auto categories-submenu">
                        <Collapse in={this.state.categoriesMenuOpen}>
                            <div className="categories-list">
                                {this.state.categories.map(category => {
                                    return (
                                        <div key={category.id} className="category-item">
                                            <a href={'/bolsas?category=' + category.id}>{category.name}</a>
                                        </div>
                                    )
                                })}
                            </div>
                        </Collapse>
                    </div>
                </div>
                <Modal show={this.state.showLoginModal} onHide={this.handleClose}>
                    <Modal.Body>
                        <div className="row d-flex justify-content-between">
                            <div className="col-11">
                                <h3 className="d-block mb-3">Entre com suas credenciais</h3>
                            </div>
                            <div className="col-1 text-center d-flex">
                                <div className="p-1 btn-close-modal" onClick={this.toggleLoginModal}><i className="fas fa-times"></i></div>
                            </div>
                        </div>
                        <form className="row p-3 d-flex flex-column login-form" onSubmit={this.handleLogin}>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="text"
                                    name="username"
                                    className="form-control"
                                    value={this.state.loginUsername}
                                    onChange={(e) => { this.setState({ loginUsername: e.target.value }) }}
                                />
                            </div>
                            <div className="form-group">
                                <label>CPF</label>
                                <input
                                    type="text"
                                    name="loginPassword"
                                    className="form-control"
                                    value={this.state.loginPassword}
                                    onChange={(e) => { this.setState({ loginPassword: e.target.value }) }}
                                />
                            </div>
                            {this.state.loginError
                                ?
                                <div className="form-group">
                                    <span className="text-danger">Credenciais invalidas</span>
                                </div>
                                :
                                null
                            }
                            <div className="row text-center d-flex align-items-center">
                                <div className="col-md-5">
                                    <button type="submit" className="btn btn-secondary btn-block text-white">ENTRAR</button>
                                </div>
                                <div className="col-md-7 mt-3 mt-md-0">
                                    <a href={"/recuperar_senha?user=" + this.state.loginUsername} className="forget-password">Esqueci minha senha</a>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
